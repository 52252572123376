import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { Mail } from 'lucide-react';

// Styled DialogTitle with close button
const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
}));

// Styled icon container
const IconContainer = styled('div')(({ theme }) => ({
    width: 64,
    height: 64,
    borderRadius: '50%',
    background: alpha(theme.palette.primary.main, 0.1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '16px auto'
}));

const ConfirmationDialog = ({ open, onClose, email }) => {
    return (
        <Dialog 
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: 2,
                    boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
                }
            }}
        >
            <StyledDialogTitle>
                <Typography variant="h6">Check Your Email</Typography>
                <IconButton 
                    aria-label="close"
                    onClick={onClose}
                    sx={{ color: 'grey.500' }}
                >
                    <CloseIcon />
                </IconButton>
            </StyledDialogTitle>

            <DialogContent sx={{ pb: 3, textAlign: 'center' }}>
                <IconContainer>
                    <Mail size={32} color="#2196F3" />
                </IconContainer>

                <Typography variant="h6" gutterBottom>
                    Please Confirm Your Email
                </Typography>

                <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
                    We've sent a confirmation link to:
                </Typography>

                <Typography 
                    variant="subtitle1" 
                    fontWeight="500" 
                    sx={{ 
                        mb: 3,
                        backgroundColor: alpha('#2196F3', 0.1),
                        py: 1,
                        px: 2,
                        borderRadius: 1,
                        display: 'inline-block'
                    }}
                >
                    {email}
                </Typography>

                <Typography variant="body2" color="text.secondary">
                    Click the link in the email to confirm your email and proceed with the assessment.
                    If you don't see the email, please check your spam folder.
                </Typography>
            </DialogContent>

            <DialogActions sx={{ px: 3, pb: 3, justifyContent: 'center' }}>
                <Button
                    variant="outlined"
                    onClick={onClose}
                    sx={{ 
                        width: '200px',
                        '&:hover': {
                            backgroundColor: alpha('#2196F3', 0.1)
                        }
                    }}
                >
                    I'll Check Later
                </Button>
            </DialogActions>
        </Dialog>
    );
};

// PropTypes
ConfirmationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
};

export default ConfirmationDialog;