import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { ChevronLeft, ChevronRight } from 'lucide-react';

// Define slideshow images
const slideImages = [
  "/slider/0.jpg",
  "/slider/1.jpg",
  "/slider/2.jpg",
  "/slider/3.jpg",
  "/slider/4.jpg",
  "/slider/5.jpg",
  "/slider/6.jpg"
];

// Styled components
const SlideContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '400px',
  borderRadius: '16px',
  position: 'relative',
  overflow: 'hidden',
  marginBottom: '24px',
}));

const Slide = styled(Box)(({ image, isActive }) => ({
  width: '100%',
  height: '100%',
  backgroundImage: `url(${image})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  position: 'absolute',
  transition: 'opacity 0.5s ease-in-out',
  opacity: isActive ? 1 : 0,
}));

const NavigationButton = styled(Box)(({ theme, position }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  left: position === 'left' ? '10px' : 'auto',
  right: position === 'right' ? '10px' : 'auto',
  background: 'rgba(255, 255, 255, 0.7)',
  borderRadius: '50%',
  width: '40px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  zIndex: 10,
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.9)',
  }
}));

const IndicatorContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: '20px',
  left: '50%',
  transform: 'translateX(-50%)',
  display: 'flex',
  gap: '8px',
  zIndex: 10,
}));

const Indicator = styled(Box)(({ theme, active }) => ({
  width: '10px',
  height: '10px',
  borderRadius: '50%',
  background: active ? theme.palette.primary.main : 'rgba(255, 255, 255, 0.7)',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
}));

const HeroSlideshow = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);
  
  // Auto-play functionality
  useEffect(() => {
    let interval;
    
    if (isAutoPlaying) {
      interval = setInterval(() => {
        setCurrentSlide((prev) => (prev === slideImages.length - 1 ? 0 : prev + 1));
      }, 5000); // Change slide every 5 seconds
    }
    
    return () => clearInterval(interval);
  }, [isAutoPlaying]);
  
  // Pause auto-play when user interacts with slideshow
  const handleUserInteraction = () => {
    setIsAutoPlaying(false);
    // Resume auto-play after 10 seconds of inactivity
    setTimeout(() => setIsAutoPlaying(true), 10000);
  };
  
  const goToPrevSlide = () => {
    handleUserInteraction();
    setCurrentSlide((prev) => (prev === 0 ? slideImages.length - 1 : prev - 1));
  };
  
  const goToNextSlide = () => {
    handleUserInteraction();
    setCurrentSlide((prev) => (prev === slideImages.length - 1 ? 0 : prev + 1));
  };
  
  const goToSlide = (index) => {
    handleUserInteraction();
    setCurrentSlide(index);
  };
  
  return (
    <SlideContainer>
      {slideImages.map((image, index) => (
        <Slide 
          key={index}
          image={image}
          isActive={index === currentSlide}
        />
      ))}
      
      <NavigationButton position="left" onClick={goToPrevSlide}>
        <ChevronLeft size={24} color="#333" />
      </NavigationButton>
      
      <NavigationButton position="right" onClick={goToNextSlide}>
        <ChevronRight size={24} color="#333" />
      </NavigationButton>
      
      <IndicatorContainer>
        {slideImages.map((_, index) => (
          <Indicator
            key={index}
            active={index === currentSlide}
            onClick={() => goToSlide(index)}
          />
        ))}
      </IndicatorContainer>
    </SlideContainer>
  );
};

export default HeroSlideshow;