import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import useAnalytics from '../hooks/useAnalytics'
// Material UI imports
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Chip from '@mui/material/Chip';
import { alpha } from '@mui/material/styles';
import Fade from '@mui/material/Fade';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { Brain, Heart, Home, Briefcase, Wallet, Activity, Users, HeartHandshake, ChevronRight, ChevronLeft, Lightbulb } from 'lucide-react';
import { jsPDF } from 'jspdf';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import DownloadIcon from '@mui/icons-material/Download';
import NavigationBanner from '../components/NavigationBanner';
import ReplayIcon from '@mui/icons-material/Replay';

// Custom styled components
const StyledSlider = styled('input')(({ theme }) => ({
  WebkitAppearance: 'none',
  width: '100%',
  height: '8px',
  borderRadius: '5px',
  background: `linear-gradient(to right, ${alpha(theme.palette.primary.main, 0.2)}, ${alpha(theme.palette.primary.main, 0.8)})`,
  outline: 'none',
  '&::-webkit-slider-thumb': {
    WebkitAppearance: 'none',
    appearance: 'none',
    width: '20px',
    height: '20px',
    borderRadius: '50%', 
    background: theme.palette.primary.main,
    cursor: 'pointer',
    boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
    transition: 'all 0.2s ease',
    '&:hover': {
      transform: 'scale(1.1)',
      boxShadow: '0 3px 8px rgba(0,0,0,0.3)',
    }
  }
}));

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: '16px',
  boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: '0 8px 30px rgba(0,0,0,0.12)',
  }
}));

const CategoryCard = styled(Card)(({ theme, level }) => {
  const colors = {
    High: theme.palette.success.light,
    Moderate: theme.palette.warning.light,
    Low: theme.palette.error.light
  };
  
  return {
    borderRadius: '12px',
    borderLeft: `4px solid ${colors[level] || theme.palette.primary.main}`,
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'translateY(-4px)'
    }
  };
});

// Categories data (same as before)
const categories = [
  {
    id: 'personal-growth',
    title: 'Personal Growth',
    description: 'Your mindset, learning, and self-improvement habits.',
    icon: Brain,
    color: '#7C4DFF',
    questions: [
      'I set clear, measurable goals for myself regularly.',
      'I actively seek opportunities to learn new skills.',
      'I reflect on my progress and adjust my goals as needed.',
      'I challenge myself to step out of my comfort zone often.',
      'I have a clear, actionable plan for long-term growth.'
    ]
  },
  {
    id: 'fun-leisure',
    title: 'Fun and Leisure',
    description: 'How well you recharge and enjoy life.',
    icon: Heart,
    color: '#FF6D00',
    questions: [
      'I prioritize activities that bring me joy and relaxation.',
      'I make time for hobbies that energize me.',
      'I explore new experiences and fun activities regularly.',
      'I feel guilt-free when I take time to relax.',
      'My leisure activities leave me feeling refreshed.'
    ]
  },
  {
    id: 'home-environment',
    title: 'Home Environment',
    description: 'Your space and its impact on your well-being.',
    icon: Home,
    color: '#00BFA5',
    questions: [
      'My home environment feels peaceful and comfortable.',
      'I keep my living space organized and clutter-free.',
      'My home reflects my personality and values.',
      'I have a designated space for relaxation or focus.',
      'My home supports quality time with loved ones.'
    ]
  },
  {
    id: 'career',
    title: 'Career',
    description: 'How aligned and fulfilling your work life is.',
    icon: Briefcase,
    color: '#2979FF',
    questions: [
      'I feel fulfilled and aligned with my career goals.',
      'I have a clear vision for my career progression.',
      'My career challenges me to grow and develop.',
      'I balance my work and personal life effectively.',
      'I feel valued and appreciated in my work environment.'
    ]
  },
  {
    id: 'money',
    title: 'Money',
    description: 'Your financial health and stability.',
    icon: Wallet,
    color: '#00C853',
    questions: [
      'I confidently manage my finances and budget effectively.',
      'I have clear, actionable financial goals.',
      'I feel prepared for unexpected financial emergencies.',
      'I consistently save or invest for the future.',
      'My financial habits align with my long-term vision.'
    ]
  },
  {
    id: 'health',
    title: 'Health',
    description: 'Your physical, mental, and emotional well-being.',
    icon: Activity,
    color: '#D50000',
    questions: [
      'I prioritize regular exercise and physical activity.',
      'I get adequate rest and sleep most nights.',
      'I manage stress effectively.',
      'I maintain a balanced diet that supports my health.',
      'I feel physically and mentally energized daily.'
    ]
  },
  {
    id: 'friends-family',
    title: 'Friends and Family',
    description: 'The quality of your relationships and support network.',
    icon: Users,
    color: '#FF4081',
    questions: [
      'My relationships are supportive and uplifting.',
      'I communicate openly with loved ones.',
      'I prioritize quality time with friends and family.',
      'I feel a sense of belonging in my relationships.',
      'I express gratitude and appreciation for those around me.'
    ]
  },
  {
    id: 'significant-other',
    title: 'Significant Other',
    description: 'Love, companionship, and shared goals.',
    icon: HeartHandshake,
    color: '#AA00FF',
    questions: [
      'My relationship feels loving and supportive.',
      'My partner and I communicate effectively.',
      'We align on long-term goals and values.',
      'I actively show appreciation for my partner.',
      'We prioritize meaningful connection and shared activities.'
    ]
  }
];

function parseChatGPTResponse(response) {
  try {
    const insights = {};
    const data = typeof response === 'string' ? JSON.parse(response) : response;

    // Map the snake_case keys from API to kebab-case keys used in categories
    const keyMapping = {
      personal_growth: 'personal-growth',
      fun_leisure: 'fun-leisure',
      home_environment: 'home-environment',
      friends_family: 'friends-family',
      significant_other: 'significant-other',
    };

    // Check if we have the new format (data.status === 'existing_assessment')
    const recommendations = data.response.recommendations;

    const summary = data.response.summary;

    // Process each category in the recommendations
    if (recommendations) {
      Object.entries(recommendations).forEach(([key, value]) => {
        const categoryId = keyMapping[key] || key;
        insights[categoryId] = {
          comment: value.comment,
          suggestions: value.suggestions,
        };
      });
    }

    return {
      overallSummary: summary || '',
      categoryInsights: insights,
    };
  } catch (error) {
    console.error('Error parsing ChatGPT response:', error);
    return {
      overallSummary: '',
      categoryInsights: {},
    };
  }
}

const PDFGenerator = ({ 
  numericScore, 
  interpretation, 
  chatGPTSummary, 
  categories, 
  calculateCategoryTotal, 
  categoryInsights,
  clientName ,
  trackPDFGeneration
}) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const generatePDF = async () => {
    if (isGenerating) return;

    // Add this line to track PDF generation
    trackPDFGeneration();

    setIsGenerating(true);
    
    try {
      // Create PDF in a try-catch block
      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
        compress: true
      });

      // Wrap the PDF generation in a Promise
      await new Promise((resolve, reject) => {
        try {
          let yPos = 20;
          const pageWidth = doc.internal.pageSize.width;
          
          // Set fonts
          doc.setFont('helvetica', 'bold');
          doc.setFontSize(20);
          
          // Title
          doc.text('Willful Positivity Assessment Results', pageWidth / 2, yPos, { align: 'center' });
          yPos += 15;
          
          // Overall Score
          doc.setFontSize(16);
          doc.text(`Overall Score: ${numericScore}`, pageWidth / 2, yPos, { align: 'center' });
          yPos += 10;
          
          // Interpretation
          doc.setFont('helvetica', 'normal');
          doc.setFontSize(12);
          doc.text(interpretation, pageWidth / 2, yPos, { align: 'center' });
          yPos += 15;
          
          // Overall Summary
          if (chatGPTSummary) {
            doc.setFont('helvetica', 'bold');
            doc.text(`Overall Analysis for ${clientName}`, 20, yPos);
            yPos += 10;
            doc.setFont('helvetica', 'normal');
            
            const summaryLines = doc.splitTextToSize(
              chatGPTSummary.split('###')[0] || chatGPTSummary, 
              pageWidth - 40
            );
            doc.text(summaryLines, 20, yPos);
            yPos += (summaryLines.length * 7) + 15;
          }
          
          // Category Results
          categories.forEach(category => {
            // Check if we need a new page
            if (yPos > 270) {
              doc.addPage();
              yPos = 20;
            }
            
            const categoryScore = calculateCategoryTotal(category.id);
            
            doc.setFont('helvetica', 'bold');
            doc.text(`${category.title}: ${categoryScore}/50`, 20, yPos);
            yPos += 7;
            
            doc.setFont('helvetica', 'normal');
            doc.text(category.description, 20, yPos);
            yPos += 7;
            
            // Add insights if available
            if (categoryInsights[category.id]) {
              const insight = categoryInsights[category.id];
              const commentLines = doc.splitTextToSize(insight.comment, pageWidth - 40);
              doc.text(commentLines, 20, yPos);
              yPos += (commentLines.length * 7) + 5;
              
              if (insight.suggestions && insight.suggestions.length > 0) {
                doc.setFont('helvetica', 'italic');
                doc.text('Suggestions:', 20, yPos);
                yPos += 7;
                
                insight.suggestions.forEach(suggestion => {
                  const suggestionLines = doc.splitTextToSize(`• ${suggestion}`, pageWidth - 45);
                  doc.text(suggestionLines, 25, yPos);
                  yPos += (suggestionLines.length * 7) + 3;
                });
              }
            }
            
            yPos += 10;
          });
          
          // Add footer with date
          doc.setFont('helvetica', 'italic');
          doc.setFontSize(10);
          const date = new Date().toLocaleDateString();
          doc.text(
            `Assessment generated on ${date}`, 
            pageWidth - 20, 
            doc.internal.pageSize.height - 10, 
            { align: 'right' }
          );

          // Save using a timeout to prevent port closing issues
          setTimeout(() => {
            try {
              doc.save('willful-positivity-assessment.pdf');
              resolve();
            } catch (err) {
              reject(err);
            }
          }, 100);

        } catch (err) {
          reject(err);
        }
      });

      setSnackbar({
        open: true,
        message: 'Your assessment PDF has been generated and downloaded.',
        severity: 'success'
      });

    } catch (error) {
      console.error('Error generating PDF:', error);
      setSnackbar({
        open: true,
        message: 'Failed to generate PDF. Please try again.',
        severity: 'error'
      });
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        disabled={isGenerating}
        color="primary"
        onClick={generatePDF}
        fullWidth
        startIcon={<DownloadIcon />}
        sx={{ py: 1.5 }}
      >
        {isGenerating ? 'Generating PDF...' : 'Download Results as PDF'}
      </Button>

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

function Assessment() {

  // For navigation
  const navigate = useNavigate();

  // Initialize analytics
  const { 
    trackAssessmentProgress, 
    trackAssessmentComplete, 
    trackCategoryScore,
    trackPDFGeneration 
  } = useAnalytics();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  // Update the useEffect block where we check for email
  useEffect(() => {
    const storedEmail = localStorage.getItem('subscriber_email');
    const subscriberType = localStorage.getItem('subscriber_type');
    const emailConfirmed = localStorage.getItem('email_confirmed') === 'true';
    const storedName = localStorage.getItem('subscriber_name') || 'User';

    if (!storedEmail || !emailConfirmed) {
        navigate('/');
    } else {
      // Identify existing user with Brevo if they have a verified email
      
      const firstName = storedName.split(" ")[0];
      const lastName = storedName.split(" ").slice(1).join(" ");
      
      window.Brevo.push(function () {
        window.Brevo.identify({
          identifiers: {
            email_id: storedEmail   /*required*/
          },
          attributes: {            /*optional*/
            FIRSTNAME: firstName,
            LASTNAME: lastName,
          },
        });
      });
    } 
    
   if (subscriberType === 'existing_subscriber') {
      const generateInitialSummary = async () => {
        try {
          setLoadingSummary(true);
          const response = await axios.post(
            'https://willfulpositivity.com/generateSummary.php',
            {
              email: storedEmail,
              isExistingUser: true
            }
          );

          const data = response.data;
          if (data.status === 'existing_assessment') {
            
            // Parse the score and keep it as is (already 0-100)
            let numeric = parseFloat(data.request.overallScore);
            setNumericScore(numeric);
            const client = data.client;
            setClientName(client);

            
            // Set interpretation directly using the 0-100 scale score
            setInterpretation(getScoreInterpretation(numeric));

            // Convert category scores
            const categoryScoresMap = {};
            
            data.request.categoryScores.forEach(category => {
              const categoryObj = categories.find(c => c.title === category.title);
              if (categoryObj) {
                const averageQuestionScore = Math.round(category.score / 5);
                categoryScoresMap[categoryObj.id] = {
                  0: averageQuestionScore,
                  1: averageQuestionScore,
                  2: averageQuestionScore,
                  3: averageQuestionScore,
                  4: averageQuestionScore
                };
              }
            });
            
            
            
            setScores(categoryScoresMap);
            setChatGPTSummary(data.response.summary);
            setCategoryInsights(parseChatGPTResponse(data).categoryInsights);
          }
        } catch (err) {
          setErrorSummary('Failed to retrieve your previous assessment. Please try taking it again.');
          console.error('Error in generateInitialSummary:', err);
          setShowResults(false);
        } finally {
          setLoadingSummary(false);
        }
      };

      setShowResults(true);
      generateInitialSummary();
    }
  }, [navigate]);
  // --- State ---
  const [currentStep, setCurrentStep] = useState(0);
  const [scores, setScores] = useState({});
  const [showResults, setShowResults] = useState(false);
  const [transitionState, setTransitionState] = useState(true);

  // ChatGPT summary states
  const [chatGPTSummary, setChatGPTSummary] = useState(null);
  const [categoryInsights, setCategoryInsights] = useState({});
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [errorSummary, setErrorSummary] = useState(null);

  // Derived states for results
  const [numericScore, setNumericScore] = useState(0);
  const [interpretation, setInterpretation] = useState('');
  const [clientName, setClientName] = useState('');

  // --- Existing Functions ---
  const handleScoreChange = (categoryId, questionIndex, value) => {
    setScores((prev) => ({
      ...prev,
      [categoryId]: {
        ...prev[categoryId],
        [questionIndex]: parseInt(value)
      }
    }));
  };

  const calculateCategoryTotal = useCallback(
    (categoryId) => {
      if (!scores[categoryId]) return 0;
      return Object.values(scores[categoryId]).reduce((sum, score) => sum + score, 0);
    },
    [scores] // 'scores' is the only dependency needed
  );

  const calculateOverallScore = useCallback(() => {
    return categories.reduce((sum, category) => 
      sum + calculateCategoryTotal(category.id), 
      0
    );
  }, [calculateCategoryTotal]);

  const getScoreInterpretation = (score) => {
    if (score >= 80) {
      return 'You\'re showing remarkable progress in your journey!';
    }
    else if (score >= 60 && score < 80) {
      return 'You\'ve built solid foundations and are making great strides';
    }
    else if (score >= 40 && score < 60) {
      return 'You\'re actively working on positive changes in your life';
    }
    else if (score >= 20 && score < 40) {
      return 'You\'re taking important first steps toward positive change';
    }
    else {
      return 'You\'re at the start of your transformation journey';
    }
  };

  const getCategoryScoreLevel = (score) => {
    if (score >= 40) return 'High';
    if (score >= 20) return 'Moderate';
    return 'Low';
  };

  // Updated generateGPTSummary function
  const generateGPTSummary = React.useCallback(
    async (overall, categoriesInfo, isRetry = false) => {
      try {
        setLoadingSummary(true);
        setErrorSummary(null);
        
        // Only clear existing data when not retrying
        if (!isRetry) {
          setChatGPTSummary(null);
          setCategoryInsights({});
        }
  
        const response = await axios.post(
          'https://willfulpositivity.com/generateSummary.php',
          {
            overallScore: overall,
            categoryScores: categoriesInfo,
            email: localStorage.subscriber_email,
            isRetry: isRetry // Add this flag for the PHP script
          }
        );
  
        const client = response.data.client;
        setClientName(client);
        const parsedData = parseChatGPTResponse(response.data);
        setChatGPTSummary(parsedData.overallSummary);
        setCategoryInsights(parsedData.categoryInsights);
        
        if (isRetry) {
          // Show success message for retry
          setSnackbar({
            open: true,
            message: 'Summary successfully generated!',
            severity: 'success'
          });
        }
      } catch (err) {
        console.error('Error generating summary:', err);
        setErrorSummary('Failed to generate summary. Please try again.');
      } finally {
        setLoadingSummary(false);
      }
    }, [/* dependencies */]
  );

  // --- Navigation Handlers ---
  const handleNext = () => {
    setTransitionState(false);
    setTimeout(() => {
      if (currentStep === categories.length - 1) {
        setShowResults(true);
      } else {
        const nextStep = currentStep + 1;
        setCurrentStep(nextStep);
        // Add this line to track progress
        trackAssessmentProgress(nextStep + 1, categories.length);
      }
      setTransitionState(true);
    }, 300);
  };

  const handlePrevious = () => {
    setTransitionState(false);
    setTimeout(() => {
      setCurrentStep((prev) => prev - 1);
      setTransitionState(true);
    }, 300);
  };

  // --- Effects ---
  useEffect(() => {
    if (showResults) {
      const overall = calculateOverallScore();
      const numeric = ((overall / 400) * 100).toFixed(1);
      setNumericScore(numeric);
  
      const interpret = getScoreInterpretation(numeric);
      setInterpretation(interpret);
      
      // Track completion and scores once when results are shown
      if (trackAssessmentComplete && trackCategoryScore) {
        trackAssessmentComplete(numeric);
        
        categories.forEach(category => {
          const categoryScore = calculateCategoryTotal(category.id);
          trackCategoryScore(category.title, categoryScore);
        });
      }
  
      const categoryScoresForGPT = categories.map((cat) => ({
        title: cat.title,
        score: calculateCategoryTotal(cat.id)
      }));
  
      generateGPTSummary(numeric, categoryScoresForGPT);
    }
  }, [showResults, calculateOverallScore, calculateCategoryTotal, generateGPTSummary, trackAssessmentComplete, trackCategoryScore]);

  // --- Rendering Functions ---
  const renderQuestion = (question, index, categoryId) => {
    const currentScore = scores[categoryId]?.[index] || 0;
    
    return (
      <div key={index} className="mb-8 bg-white p-5 rounded-lg shadow-sm">
        <p className="mb-4 text-gray-800 font-medium">{question}</p>
        <div className="flex flex-col gap-2">
          <StyledSlider
            type="range"
            min="1"
            max="10"
            value={currentScore}
            onChange={(e) => handleScoreChange(categoryId, index, e.target.value)}
            className="w-full"
          />
          
          <div className="flex justify-between mt-1" style={{display:'flex',justifyContent:'space-between',marginTop:'10px'}}>
            <span className="text-gray-400 text-xs">Rarely</span>
            <span className="text-gray-400 text-xs">Sometimes</span>
            <span className="text-gray-400 text-xs">Often</span>
            <span className="text-gray-400 text-xs">Almost Always</span>
          </div>
          
          <div className="mt-3 flex justify-center">
          <br />
            <Chip 
              label={`Your rating: ${currentScore}/10`}
              color="primary" 
              variant="outlined"
              size="small"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderCategory = (category) => {
    const Icon = category.icon;
    
    return (
      <Fade in={transitionState} timeout={500}>
        <div className="space-y-6">
          <div className="text-center mb-8">
            <div className="flex items-center justify-center mb-4">
              <div 
                className="w-12 h-12 rounded-full flex items-center justify-center mb-2"
                style={{ backgroundColor: alpha(category.color, 0.1) }}
              >
                <Icon size={24} style={{ color: category.color }} />
              </div>
            </div>
            
            <Typography variant="h5" component="h2" className="font-bold mb-3" style={{ color: category.color }}>
              {category.title}
            </Typography>
            
            <Typography variant="body1" className="text-gray-600 max-w-md mx-auto">
              {category.description}
            </Typography>
          </div>
          
          <div className="bg-gray-50 p-5 rounded-xl">
            {category.questions.map((question, i) => renderQuestion(question, i, category.id))}
          </div>
        </div>
      </Fade>
    );
  };

  const renderCategoryInsights = (insight) => {
    if (!insight) return null;
  
    return (
      <div className="mt-3 p-3 bg-gray-50 rounded-lg border border-gray-100">
        <Typography variant="body2" className="text-gray-700 mb-2">
          {insight.comment}
        </Typography>
        {insight.suggestions && insight.suggestions.length > 0 && (
          <div className="mt-2">
            <Typography variant="body2" className="text-gray-700 font-medium mb-1">
              Suggestions:
            </Typography>
            <ul className="list-disc pl-4 space-y-1">
              {insight.suggestions.map((suggestion, index) => (
                <li key={index}>
                  <Typography variant="body2" className="text-gray-700">
                    {suggestion}
                  </Typography>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  };

  const GaugeChart = ({ score }) => {
    // Determine color and label based on score
    const getColorAndLabel = (value) => {
      if (value >= 80) {
        return { 
          color: '#4CAF50', // Strong green
          label: 'Exceptional'
        };
      } else if (value >= 60 && value < 80) {
        return { 
          color: '#8BC34A', // Light green
          label: 'Strong'
        };
      } else if (value >= 40 && value < 60) {
        return { 
          color: '#FFC107', // Amber
          label: 'Growing'
        };
      } else if (value >= 20 && value < 40) {
        return { 
          color: '#FF9800', // Orange
          label: 'Developing'
        };
      }
      return { 
        color: '#F44336', // Red
        label: 'Beginning'
      };
    };
  
    const { color, label} = getColorAndLabel(score);
    
    // Calculate angles for the gauge
    const startAngle = -180;
    const endAngle = 0;
    const range = endAngle - startAngle;
    const valueAngle = startAngle + (range * (score / 100));
    
    // Calculate points for the needle
    const centerX = 275;
    const centerY = 150;
    const needleLength = 100;
    const needleAngleRad = (valueAngle * Math.PI) / 180;
    const needleX = centerX + needleLength * Math.cos(needleAngleRad);
    const needleY = centerY + needleLength * Math.sin(needleAngleRad);
  
    // Generate tick marks
    const generateTicks = () => {
      const ticks = [];
      for (let i = 0; i <= 10; i++) {
        const angle = startAngle + (range * (i / 10));
        const angleRad = (angle * Math.PI) / 180;
        const outerRadius = 120;
        const innerRadius = 110;
        
        const outerX = centerX + outerRadius * Math.cos(angleRad);
        const outerY = centerY + outerRadius * Math.sin(angleRad);
        const innerX = centerX + innerRadius * Math.cos(angleRad);
        const innerY = centerY + innerRadius * Math.sin(angleRad);
        
        ticks.push(
          <line
            key={i}
            x1={innerX}
            y1={innerY}
            x2={outerX}
            y2={outerY}
            stroke="#999"
            strokeWidth="1"
          />
        );
  
        // Add labels for some ticks
        if (i % 2 === 0) {
          const labelRadius = 130;
          const labelX = centerX + labelRadius * Math.cos(angleRad);
          const labelY = centerY + labelRadius * Math.sin(angleRad);
          ticks.push(
            <text
              key={`label-${i}`}
              x={labelX}
              y={labelY}
              textAnchor="middle"
              dominantBaseline="middle"
              fontSize="10"
              fill="#666"
            >
              {i * 10}
            </text>
          );
        }
      }
      return ticks;
    };
  
    return (
      <div className="fixed inset-0 flex items-center justify-center">
        <div className="flex flex-col items-center">
          <div className="w-96 h-96">
            <svg viewBox="0 0 550 190" className="w-full h-full">
              {/* Background arc */}
              <path
                d={`M ${centerX} ${centerY} L ${centerX - 120} ${centerY} A 120 120 0 0 1 ${centerX + 120} ${centerY} Z`}
                fill="none"
                stroke="#e0e0e0"
                strokeWidth="15"
                strokeLinecap="round"
              />
              
              {/* Ticks */}
              {generateTicks()}
              
              {/* Needle */}
              <path
                d={`M ${centerX} ${centerY} L ${needleX} ${needleY}`}
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
              />
              
              {/* Center circle */}
              <circle
                cx={centerX}
                cy={centerY}
                r="8"
                fill={color}
              />
              
              {/* Score display */}
              <text
                x={centerX}
                y={centerY + 35}
                textAnchor="middle"
                fontSize="24"
                fontWeight="bold"
                fill={color}
              >
                {score} 
              </text>
            </svg>
          </div>
          
          {/* Labels */}
          <div className="text-center mt-4">
            <div className="text-2xl font-bold" style={{ color, textAlign:'center' }}>
              {label}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderResults = () => {
    return (
      <Fade in={true} timeout={800}>
        <div className="space-y-8">
          <div className="text-center">
            <Typography variant="h5" className="mb-4 font-bold" style={{ textAlign:'center' }}>
              Your Willful Positivity Score
            </Typography>
            
            <GaugeChart score={numericScore} />
            
            <Typography variant="h6" className="text-gray-600 mb-6" style={{marginBottom:'10px',marginTop:'10px',textAlign:'center'}}>
            {interpretation}
            </Typography>
          </div>

          {/* Action Buttons */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-8" style={{marginTop:'10px',marginBottom:'20px'}}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/ScheduleCall')}
              fullWidth
              sx={{ py: 1.5 }}
            >
              Schedule a Call with Coach Alma
            </Button>
            <div style={{marginTop:'10px'}}></div>
            <PDFGenerator 
              numericScore={numericScore}
              interpretation={interpretation}
              chatGPTSummary={chatGPTSummary}
              categories={categories}
              calculateCategoryTotal={calculateCategoryTotal}
              categoryInsights={categoryInsights}
              clientName={clientName}
              trackPDFGeneration={trackPDFGeneration}
            />
          </div>

          {/* Overall Summary Section */}
          <StyledCard className="p-6" style={{marginBottom:'20px',padding:'20px'}}>
            <div className="flex items-start gap-4">
              <div className="bg-blue-50 rounded-full p-3 mt-1">
                <Lightbulb size={24} className="text-blue-500" />
              </div>
              <div className="flex-1">
                <Typography variant="h6" className="mb-4 font-semibold">
                  Overall Analysis for {clientName}
                </Typography>
                {loadingSummary ? (
                  <div className="space-y-3">
                    <Typography variant="body2" className="text-gray-500">
                      Analyzing your results...
                    </Typography>
                    <LinearProgress color="primary" />
                  </div>
                ) : errorSummary ? (
                  <div className="bg-red-50 p-3 rounded-md">
                    <Typography className="text-red-600 mb-2">{errorSummary}</Typography>
                    <Button 
                      variant="outlined"
                      color="error"
                      size="small"
                      startIcon={<ReplayIcon />}
                      onClick={() => {
                        // Get the existing data to retry
                        const overall = numericScore;
                        const categoryScoresForGPT = categories.map((cat) => ({
                          title: cat.title,
                          score: calculateCategoryTotal(cat.id)
                        }));
                        
                        // Pass retry flag
                        generateGPTSummary(overall, categoryScoresForGPT, true);
                        
                        // Show snackbar notification
                        setSnackbar({
                          open: true,
                          message: 'Retrying to generate your assessment summary...',
                          severity: 'info'
                        });
                      }}
                    >
                      Retry
                    </Button>
                  </div>
                ) : chatGPTSummary ? (
                  <div className="prose max-w-none text-gray-700">
                    <Typography variant="body1">
                      {chatGPTSummary.split('###')[0]}
                    </Typography>
                  </div>
                ) : null}
              </div>
            </div>
          </StyledCard>

          {/* Category Results */}
          <div className="grid gap-4">
            {categories.map((category) => {
              const categoryScore = calculateCategoryTotal(category.id);
              const scoreLevel = getCategoryScoreLevel(categoryScore);
              const Icon = category.icon;
              //const insight = categoryInsights[category.id]

              return (
                <CategoryCard key={category.id} level={scoreLevel} className="p-4" style={{marginBottom:'10px',padding:'10px'}}>
                  <div className="flex items-center gap-3 mb-2">
                    <div 
                      className="w-10 h-10 rounded-full flex items-center justify-center" 
                      style={{ backgroundColor: alpha(category.color, 0.1) }}
                    >
                      <Icon size={20} style={{ color: category.color }} />
                    </div>
                    <div className="flex-1">
                      <div className="flex justify-between items-center">
                        <Typography variant="subtitle1" className="font-semibold">
                          {category.title}
                        </Typography>
                        <Typography variant="subtitle1" className="font-bold">
                          {categoryScore}/50
                        </Typography>
                      </div>
                      <div className="w-full bg-gray-200 h-2 rounded-full mt-1">
                        <div
                          className="h-2 rounded-full transition-all duration-500"
                          style={{ 
                            width: `${(categoryScore / 50) * 100}%`,
                            backgroundColor: category.color 
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  
                  <div className="ml-12 mt-2">
                    <Typography variant="body2" className="text-gray-600 mb-2">
                      {scoreLevel === 'High'
                        ? 'Keep up the great work!'
                        : scoreLevel === 'Moderate'
                        ? 'Room for growth'
                        : 'Focus area for improvement'}
                    </Typography>
                    
                    {loadingSummary ? (
                      <Typography variant="body2" className="text-gray-500 italic">
                        Loading insights...
                      </Typography>
                    ) : categoryInsights[category.id] ? (
                      renderCategoryInsights(categoryInsights[category.id])
                    ) : null}
                  </div>
                </CategoryCard>
              );
            })}
          </div>

        </div>
      </Fade>
    );
  };

  return (
    <>
      <NavigationBanner />
    <div className="flex justify-center items-center min-h-screen bg-gray-50 py-8 px-4" style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
      backgroundColor: '#F9FAFB',
      padding: '2rem 1rem',
      marginTop: '70px'
    }}>
      <StyledCard sx={{ maxWidth: '44rem', width: '100%', p: { xs: 2, md: 4 } }}>
        <CardContent>
          {!showResults ? (
            <>
              {renderCategory(categories[currentStep])}

              <div className="flex justify-between mt-8">
              <br />
                {currentStep > 0 && (
                  <Button
                    onClick={handlePrevious}
                    variant="outlined"
                    className="flex items-center gap-2"
                    startIcon={<ChevronLeft className="w-4 h-4" />}
                  >
                    Previous
                  </Button>
                )}
                {currentStep > 0 && (
                  <span style={{paddingRight:'10px'}}></span>
                )}
                <Button
                  onClick={handleNext}
                  variant="contained"
                  className="flex items-center gap-2 ml-auto"
                  endIcon={currentStep !== categories.length - 1 && <ChevronRight className="w-4 h-4" />}
                >
                  {currentStep === categories.length - 1 ? 'View Results' : 'Next'}
                </Button>
              </div>

              <div className="mt-6 mb-4">
              <br />
                <div className="flex items-center justify-between mb-2">
                  <Typography variant="body2" className="text-gray-600">
                    Progress
                  </Typography>
                  <Typography variant="body2" className="text-gray-600 font-medium">
                    Step {currentStep + 1} of {categories.length}
                  </Typography>
                </div>
                <LinearProgress 
                  variant="determinate" 
                  value={(currentStep / (categories.length - 1)) * 100} 
                  sx={{ height: 8, borderRadius: 4 }}
                />
              </div>

              <Button
                variant="text"
                onClick={() => navigate('/ScheduleCall')}
                fullWidth
                sx={{ mt: 2 }}
              >
                Skip assessment and schedule a call with Coach Alma
              </Button>
              <span style={{paddingBottom:'10px'}}></span>
              <Button
                variant="text"
                onClick={() => navigate('/')}
                fullWidth
                sx={{ mt: 2 }}
              >
                Go Back to Home Page
              </Button>
            </>
          ) : (
            renderResults()
          )}
        </CardContent>
      </StyledCard>
    </div>
    <Snackbar 
      open={snackbar.open} 
      autoHideDuration={6000} 
      onClose={handleCloseSnackbar}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert 
        onClose={handleCloseSnackbar} 
        severity={snackbar.severity}
        sx={{ width: '100%' }}
      >
        {snackbar.message}
      </Alert>
    </Snackbar>
    </>
  );
}

export default Assessment;