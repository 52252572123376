import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAnalytics from '../hooks/useAnalytics';
import {Heart, Brain, ArrowRight, Star, CheckCircle, Award, Shield, Zap, MousePointer, LayoutGrid, BarChart3 } from 'lucide-react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NavigationBanner from '../components/NavigationBanner';
import ConfirmationDialog from '../components/ConfirmationDialog';
import VideoPlayer from '../components/VideoPlayer';
import HeroSlideshow from '../components/HeroSlideshow';

// Custom styled components
const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: '16px',
  boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
  transition: 'all 0.3s ease',
  overflow: 'visible',
  height: '100%',
  '&:hover': {
    boxShadow: '0 8px 30px rgba(0,0,0,0.12)',
    transform: 'translateY(-5px)',
  }
}));

const IconBox = styled('div')(({ theme }) => ({
  width: '48px',
  height: '48px',
  borderRadius: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: alpha(theme.palette.primary.main, 0.12),
  color: theme.palette.primary.main,
  flexShrink: 0,
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #2196F3, #673AB7)',
  color: 'white',
  padding: '12px 24px',
  fontWeight: 600,
  borderRadius: '8px',
  '&:hover': {
    background: 'linear-gradient(45deg, #1E88E5, #5E35B1)',
    boxShadow: '0 6px 20px rgba(0,0,0,0.15)',
  }
}));

const OutlinedButton = styled(Button)(({ theme }) => ({
  background: 'transparent',
  border: '2px solid #2196F3',
  color: '#2196F3',
  padding: '12px 24px',
  fontWeight: 600,
  borderRadius: '8px',
  '&:hover': {
    background: alpha('#2196F3', 0.1),
    borderColor: '#1E88E5',
  }
}));

const SectionDivider = styled(Divider)(({ theme }) => ({
  
  '&::before, &::after': {
    borderColor: alpha(theme.palette.primary.main, 0.2),
  }
}));

const PainPointCard = styled(Card)(({ theme }) => ({
  borderRadius: '16px',
  boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
  transition: 'all 0.3s ease',
  height: '100%',
  borderLeft: `4px solid ${theme.palette.primary.main}`,
  '&:hover': {
    boxShadow: '0 8px 30px rgba(0,0,0,0.12)',
    transform: 'translateY(-5px)',
  }
}));

const PillarCard = styled(Card)(({ theme, color }) => ({
  borderRadius: '16px',
  boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
  transition: 'all 0.3s ease',
  height: '100%',
  borderTop: `4px solid ${color || theme.palette.primary.main}`,
  '&:hover': {
    boxShadow: '0 8px 30px rgba(0,0,0,0.12)',
    transform: 'translateY(-5px)',
  }
}));

  const Guarantee = styled(Box)(({ theme }) => ({
    width: '100%',
    height: '500px',
    borderRadius: '16px',
    backgroundImage: 'url("/guarantee.png")',
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    marginBottom: '24px',
  }));

const TestimonialCard = styled(Card)(({ theme }) => ({
  borderRadius: '16px',
  boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
  transition: 'all 0.3s ease',
  overflow: 'visible',
  height: '100%',
  position: 'relative',
  '&:hover': {
    boxShadow: '0 8px 30px rgba(0,0,0,0.12)',
    transform: 'translateY(-5px)',
  }
}));

// const ImagePlaceholder = styled(Box)(({ theme, height }) => ({
//   width: '100%',
//   height: height || '200px',
//   borderRadius: '8px',
//   backgroundColor: alpha(theme.palette.primary.main, 0.05),
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   border: `1px dashed ${alpha(theme.palette.primary.main, 0.3)}`,
//   marginBottom: '16px',
// }));

const LandingPage = () => {
  const navigate = useNavigate();
  const { trackEvent } = useAnalytics();
  const [name, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);

  // Track scroll depth
  useEffect(() => {
    let ticking = false;
    const scrollThresholds = [25, 50, 75, 90];
    const reachedThresholds = new Set();

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          const scrollPercent = (window.scrollY + window.innerHeight) / document.documentElement.scrollHeight * 100;
          
          scrollThresholds.forEach(threshold => {
            if (scrollPercent >= threshold && !reachedThresholds.has(threshold)) {
              trackEvent('scroll_depth', { depth: `${threshold}%` });
              reachedThresholds.add(threshold);
            }
          });

          ticking = false;
        });

        ticking = true;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [trackEvent]);

  // Track section visibility
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            trackEvent('section_viewed', {
              id: entry.target.id
            });
          }
        });
      },
      { threshold: 0.2 }
    );

    const sectionElements = document.querySelectorAll('[id^="section-"]');
    sectionElements.forEach(el => observer.observe(el));

    return () => observer.disconnect();
  }, [trackEvent]);

  const handleNameChange = (e) => {
    setFullName(e.target.value);
    if (e.target.value.trim() && !name.trim()) {
      trackEvent('name_field_entered');
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (e.target.value.trim() && !email.trim()) {
      trackEvent('email_field_entered');
    }
  };

  const handleScheduleCall = () => {
    trackEvent('schedule_call_clicked', {
      source: 'landing_page'
    });
    navigate('/ScheduleCall');
  };

  const handleStartAssessment = () => {
    trackEvent('start_assessment_clicked', {
      source: 'landing_page'
    });
    handleSubscribeAndProceed();
  };

  const handleSubscribeAndProceed = async () => {
    setErrorMessage('');
    
    if (!name.trim() || !email.trim()) {
      setErrorMessage('Please enter your full name and email address.');
      return;
    }
  
    var firstName = name.split(" ")[0];
    var lastName = name.split(" ").slice(1).join(" ");
  
    // Identify user with Brevo
    window.Brevo.push(function () {
        window.Brevo.identify({
            identifiers: {
                email_id: email   /*required*/
            },
            attributes: {            /*optional*/
                FIRSTNAME: firstName,
                LASTNAME: lastName,
            },
        });
    });
  
    try {
      const response = await fetch('https://willfulpositivity.com/subscribe.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.subscriber_type = data.data;
        localStorage.subscriber_email = data.email;
        localStorage.subscriber_name = name;
        localStorage.email_confirmed = data.emailConfirmed;
        
        if (!data.emailConfirmed) {
          setShowConfirmationMessage(true);
        } else {
          navigate('/Assessment');
        }
      } else {
        setErrorMessage('Subscription failed or you are already subscribed.');
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('An error occurred while subscribing. Please try again.');
    }
  };

  const painPoints = [
    {
      title: "I Keep Sabotaging Myself.",
      description: "You've worked so hard, yet every time success is within reach, something pulls you back. It's frustrating and disheartening.",
      cause: "Self-sabotage often stems from hidden beliefs—thinking you don't deserve success or fearing it will slip away.",
      solution: "Together, we'll uncover and replace these beliefs with empowering ones. Imagine confidently stepping into opportunities, knowing nothing will hold you back.",
      icon: <Shield size={24} color="#2196F3" />,
    },
    {
      title: "Why Can't I Stay Focused?",
      description: "You're juggling a million responsibilities, yet none seem to move forward. You feel scattered, frustrated, and stuck.",
      cause: "A lack of clear direction drains your energy and focus.",
      solution: "I'll help you prioritize what matters most and create a roadmap for meaningful progress. Regain control of your time, stay focused, and finally achieve the results you've been chasing.",
      icon: <MousePointer size={24} color="#673AB7" />,
    },
    {
      title: "I Don't Want to Get My Hopes Up.",
      description: "Past disappointments make it hard to believe this time will be different. You dream of more but lower your expectations to avoid the pain of failure.",
      cause: "Fear of disappointment holds you back from fully believing in your potential.",
      solution: "We'll create a mindset that balances optimism with actionable strategies. You'll rediscover the courage to dream big and the tools to make those dreams real—without fear.",
      icon: <Heart size={24} color="#E91E63" />,
    },
    {
      title: "I Can't Handle Another Failure.",
      description: "Setbacks feel like proof that you're not enough, leaving you frozen in place.",
      cause: "Fear of failure creates a cycle of avoidance and stagnation.",
      solution: "Through resilience-building practices and mindset shifts, you'll learn to transform failure into growth. You'll feel stronger, more equipped, and ready to face challenges head-on.",
      icon: <Zap size={24} color="#FF9800" />,
    }
  ];

  const pillars = [
    {
      title: "The Self-Mastery Mindset",
      what: "Break free from self-sabotage with visualization exercises, daily reflections, and transformative techniques.",
      why: "Self-mastery unlocks the courage and clarity you need to take action without hesitation, empowering you to achieve your full potential.",
      icon: <Brain size={32} />,
      color: "#2196F3"
    },
    {
      title: "The Focus Blueprint",
      what: "Prioritize your goals and create a clear action plan with tailored strategies and accountability.",
      why: "Focus is the foundation of success. By mastering this pillar, you'll eliminate distractions, avoid burnout, and hit your milestones with consistency.",
      icon: <LayoutGrid size={32} />,
      color: "#673AB7"
    },
    {
      title: "Resilience Reset",
      what: "Build emotional resilience and reframe setbacks as opportunities. Learn actionable techniques to face challenges with confidence.",
      why: "Resilience keeps you moving forward. This pillar empowers you to dream bigger, rise above fear, and achieve more than you ever thought possible.",
      icon: <BarChart3 size={32} />,
      color: "#E91E63"
    }
  ];

  const testimonials = [
    {
      name: "Sarah Johnson",
      role: "Marketing Executive",
      content: "The Willful Positivity program helped me break free from self-doubt and procrastination. I've achieved more in 60 days than in the entire year before!",
      rating: 5,
      image: "sarah-johnson.jpg"
    },
    {
      name: "Michael Chen",
      role: "Startup Founder",
      content: "This program provided exactly the structure and guidance I needed. I've gained clarity, focus, and the resilience to overcome challenges that used to stop me in my tracks.",
      rating: 5,
      image: "michael-chen.jpg"
    },
    {
      name: "Jessica Rivera",
      role: "Healthcare Professional",
      content: "I was skeptical at first, but the transformation has been remarkable. I've replaced negative thought patterns with empowering beliefs and finally have the momentum to pursue my dreams.",
      rating: 5,
      image: "jessica-rivera.jpg"
    }
  ];

  const faqs = [
    {
      question: "How is this different from other mindset programs?",
      answer: "The Willful Positivity program combines proven psychological techniques with personalized coaching. Unlike generic self-help programs, we create a customized roadmap based on your unique challenges, strengths, and goals. Our approach integrates mindset work with practical action steps to ensure lasting transformation."
    },
    {
      question: "How long before I see results?",
      answer: "Many clients report noticeable shifts in their mindset and behavior within the first two weeks. The program is structured to deliver meaningful progress within 60 days, with most participants experiencing significant transformation in their confidence, clarity, and momentum by the end of the program."
    },
    {
      question: "Do I need to have clear goals already?",
      answer: "Not at all! Many people come to the program feeling unclear or overwhelmed. The Willful Positivity Assessment and initial coaching sessions are specifically designed to help you gain clarity and direction. We'll work together to identify your core values and create meaningful goals that align with your authentic self."
    },
    {
      question: "How much time will I need to commit?",
      answer: "The program is designed to fit into busy schedules. Most participants dedicate 3-5 hours per week, including coaching sessions and personal practice. The techniques and strategies you'll learn are designed to integrate seamlessly into your daily life rather than adding to your to-do list."
    }
  ];

  return (
    <>
      <NavigationBanner />
      <div style={{ 
        background: 'linear-gradient(180deg, #F0F7FF 0%, #FFFFFF 100%)',
        padding: '118px 0 48px 0' // Added top padding to account for nav bar
      }}>
        <Container maxWidth="lg">
          {/* Hero Section */}
          <Box id="section-hero" sx={{ pt: 4, pb: 8, textAlign: 'center' }}>
            <Typography
              variant="h2"
              component="h1"
              sx={{
                fontWeight: 'bold',
                background: 'linear-gradient(45deg, #2196F3, #673AB7)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                mb: 3
              }}
            >
              Transform Your Life in Just 60 Days
            </Typography>
            
            <Typography variant="h5" color="text.secondary" sx={{ mb: 4, maxWidth: '800px', mx: 'auto' }}>
              Gain crystal-clear direction, structured goals, and unstoppable momentum—guaranteed
            </Typography>
            <HeroSlideshow />
            <Typography variant="h6" sx={{ mb: 4, maxWidth: '800px', mx: 'auto', fontWeight: 'normal' }}>
              You don't need to stay stuck in procrastination, fear of failure, or second-guessing yourself.
              Break free from self-doubt, master your mindset, and take bold action toward your dreams.
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, flexWrap: 'wrap' }}>
              <GradientButton 
                variant="contained" 
                size="large" 
                onClick={handleScheduleCall}
                endIcon={<ArrowRight />}
              >
                Book Your Free Clarity Call
              </GradientButton>
              
              <OutlinedButton 
                variant="outlined" 
                size="large" 
                onClick={() => navigate('/TakeAssessment')}
                endIcon={<ArrowRight />}
              >
                Take the Free Assessment
              </OutlinedButton>
            </Box>

            {/* Social Proof */}
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 4, mt: 5, flexWrap: 'wrap' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Star size={20} color="#FFB400" />
                <Typography variant="body1" fontWeight="600">4.9/5</Typography>
                <Typography variant="body2" color="text.secondary">(200+ reviews)</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <CheckCircle size={20} color="#4CAF50" />
                <Typography variant="body2" color="text.secondary">1000+ Lives Transformed</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Award size={20} color="#673AB7" />
                <Typography variant="body2" color="text.secondary">60-Day Transformation Guarantee</Typography>
              </Box>
            </Box>
          </Box>

          <SectionDivider />

          {/* Who This Is For Section */}
          <Box id="section-who" sx={{ py: 6 }}>
            <Typography variant="h4" component="h2" fontWeight="bold" sx={{ mb: 4, textAlign: 'center' }}>
              Who This Is For
            </Typography>
            
            <Grid container spacing={4} disableEqualOverflow>
              <Grid item xs={12} md={6} disableEqualOverflow>
                <VideoPlayer 
                    src="/videos/coach-alma-intro.mp4" 
                    poster="/coachAlma.jpg"
                    title="Hi, I'm Coach Alma"
                    fallbackText="Hi, I'm Coach Alma. In this video, I explain who can benefit most from the Willful Positivity program and how it can transform your life in just 60 days."
                />
              </Grid>  
              <Grid item xs={12} md={6} disableEqualOverflow>
                <StyledCard>
                  <CardContent sx={{ p: 4 }}>
                    <Typography variant="h6" fontWeight="600" sx={{ mb: 3 }}>
                      This program is perfect for you if you're:
                    </Typography>
                    
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                      {[
                        "Ready to break free from self-sabotage and build unshakable confidence.",
                        "Tired of feeling stuck, overwhelmed, or unsure where to focus your energy.",
                        "Determined to transform setbacks into stepping stones for personal and professional success.",
                        "Committed to taking control of your mindset to create a purposeful, fulfilling life."
                      ].map((item, index) => (
                        <Box key={index} sx={{ display: 'flex', gap: 2 }}>
                          <CheckCircle size={24} color="#4CAF50" />
                          <Typography variant="body1">{item}</Typography>
                        </Box>
                      ))}
                    </Box>
                    
                    <Box sx={{ mt: 4, textAlign: 'center' }}>
                      <GradientButton 
                        variant="contained" 
                        onClick={handleScheduleCall}
                        endIcon={<ArrowRight />}
                        fullWidth
                      >
                        Don't Wait—Book Your Free Call Now
                      </GradientButton>
                      <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                        Your clarity and confidence are just one call away
                      </Typography>
                    </Box>
                  </CardContent>
                </StyledCard>
              </Grid>
            </Grid>
          </Box>

          <SectionDivider />

          {/* Pain Points Section */}
          <Box id="section-pain-points" sx={{ py: 6 }}>
            <Typography variant="h4" component="h2" fontWeight="bold" sx={{ mb: 2, textAlign: 'center' }}>
              Does This Sound Familiar?
            </Typography>
            
            <Typography variant="body1" color="text.secondary" sx={{ mb: 6, textAlign: 'center', maxWidth: '800px', mx: 'auto' }}>
              If you're experiencing any of these challenges, you're not alone. They're common obstacles on the path to success—and they can be overcome.
            </Typography>
            
            <Grid container spacing={4} disableEqualOverflow>
              {painPoints.map((point, index) => (
                <Grid item xs={12} md={6} key={index} disableEqualOverflow>
                  <PainPointCard>
                    <CardContent sx={{ p: 4 }}>
                      <Box sx={{ display: 'flex', gap: 2, mb: 2, alignItems: 'center' }}>
                        <IconBox>
                          {point.icon}
                        </IconBox>
                        <Typography variant="h6" fontWeight="bold">
                          {point.title}
                        </Typography>
                      </Box>
                      
                      <Typography variant="body1" sx={{ mb: 2 }}>
                        {point.description}
                      </Typography>
                      
                      <Typography variant="subtitle2" fontWeight="bold" color="text.secondary" sx={{ mb: 1 }}>
                        Why it happens:
                      </Typography>
                      <Typography variant="body2" sx={{ mb: 2 }}>
                        {point.cause}
                      </Typography>
                      
                      <Typography variant="subtitle2" fontWeight="bold" color="text.secondary" sx={{ mb: 1 }}>
                        How we'll solve it:
                      </Typography>
                      <Typography variant="body2" sx={{ mb: 3 }}>
                        {point.solution}
                      </Typography>
                      
                      <OutlinedButton 
                        variant="outlined" 
                        onClick={handleScheduleCall}
                        endIcon={<ArrowRight size={16} />}
                        fullWidth
                        size="small"
                      >
                        Book Your Free Clarity Call
                      </OutlinedButton>
                    </CardContent>
                  </PainPointCard>
                </Grid>
              ))}
            </Grid>
          </Box>

          <SectionDivider />

          {/* Three Pillars Section */}
          <Box id="section-pillars" sx={{ py: 6 }}>
            <Typography variant="h4" component="h2" fontWeight="bold" sx={{ mb: 2, textAlign: 'center' }}>
              How This Program Helps You
            </Typography>
            
            <Typography variant="body1" color="text.secondary" sx={{ mb: 6, textAlign: 'center', maxWidth: '800px', mx: 'auto' }}>
              The path to clarity, confidence, and momentum lies in mastering these Three Key Pillars:
            </Typography>
            
            <Grid container spacing={4} disableEqualOverflow>
              {pillars.map((pillar, index) => (
                <Grid item xs={12} md={4} key={index} disableEqualOverflow>
                  <PillarCard color={pillar.color}>
                    <CardContent sx={{ p: 4 }}>
                      <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        mb: 3, 
                        height: '80px', 
                        alignItems: 'center',
                        color: pillar.color
                      }}>
                        {pillar.icon}
                      </Box>
                      
                      <Typography variant="h6" fontWeight="bold" sx={{ mb: 2, textAlign: 'center', color: pillar.color }}>
                        {pillar.title}
                      </Typography>
                      
                      <Typography variant="subtitle2" fontWeight="bold" color="text.secondary" sx={{ mb: 1 }}>
                        What you'll do:
                      </Typography>
                      <Typography variant="body2" sx={{ mb: 2 }}>
                        {pillar.what}
                      </Typography>
                      
                      <Typography variant="subtitle2" fontWeight="bold" color="text.secondary" sx={{ mb: 1 }}>
                        Why it matters:
                      </Typography>
                      <Typography variant="body2" sx={{ mb: 3 }}>
                        {pillar.why}
                      </Typography>
                    </CardContent>
                  </PillarCard>
                </Grid>
              ))}
            </Grid>
            
            <Box sx={{ textAlign: 'center', mt: 6 }}>
              <GradientButton 
                variant="contained" 
                size="large" 
                onClick={handleScheduleCall}
                endIcon={<ArrowRight />}
              >
                Take the First Step Today
              </GradientButton>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Your 60-day transformation starts with one conversation
              </Typography>
            </Box>
          </Box>

          <SectionDivider />

          {/* Testimonials Section */}
          <Box id="section-testimonials" sx={{ py: 6 }}>
            <Typography variant="h4" component="h2" fontWeight="bold" sx={{ mb: 6, textAlign: 'center' }}>
              Success Stories
            </Typography>
            
            <Grid container spacing={4} disableEqualOverflow>
              {testimonials.map((testimonial, index) => (
                <Grid item xs={12} md={4} key={index} disableEqualOverflow>
                  <TestimonialCard>
                    <CardContent sx={{ p: 4 }}>
                      {/* <ImagePlaceholder height="120px">
                        <Typography variant="body2" color="text.secondary">
                          Photo of {testimonial.name}
                        </Typography>
                      </ImagePlaceholder> */}
                      
                      <Box sx={{ display: 'flex', gap: 0.5, mb: 2 }}>
                        {[...Array(testimonial.rating)].map((_, i) => (
                          <Star key={i} size={20} color="#FFB400" fill="#FFB400" />
                        ))}
                      </Box>
                      
                      <Typography variant="body1" sx={{ mb: 3, fontStyle: 'italic' }}>
                        "{testimonial.content}"
                      </Typography>
                      
                      <Typography variant="subtitle1" fontWeight="bold">
                        {testimonial.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {testimonial.role}
                      </Typography>
                    </CardContent>
                  </TestimonialCard>
                </Grid>
              ))}
            </Grid>
          </Box>

          <SectionDivider />

          {/* FAQ Section */}
          <Box id="section-faq" sx={{ py: 6 }}>
            <Typography variant="h4" component="h2" fontWeight="bold" sx={{ mb: 6, textAlign: 'center' }}>
              Frequently Asked Questions
            </Typography>
            
            <Box sx={{ maxWidth: '800px', mx: 'auto' }}>
              {faqs.map((faq, index) => (
                <Accordion key={index} sx={{ mb: 2, borderRadius: '8px', overflow: 'hidden' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                  >
                    <Typography variant="subtitle1" fontWeight="600">
                      {faq.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body1">
                      {faq.answer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          </Box>

          <SectionDivider />

          {/* Email Signup Section */}
          <Box id="section-signup" sx={{ py: 6, textAlign: 'center' }}>
            <Typography variant="h4" component="h2" fontWeight="bold" sx={{ mb: 2 }}>
              Your Transformation Starts Here
            </Typography>
            
            <Typography variant="body1" sx={{ mb: 4, maxWidth: '700px', mx: 'auto' }}>
              Are you ready to gain clarity and direction, overcome self-doubt and fear of failure, and build unstoppable momentum toward your dreams?
            </Typography>
            
            <Box sx={{ maxWidth: '500px', mx: 'auto', p: 3, bgcolor: 'rgba(33, 150, 243, 0.05)', borderRadius: '16px', border: '1px solid rgba(33, 150, 243, 0.1)' }}>
              <Typography variant="h6" fontWeight="600" sx={{ mb: 3 }}>
                Take the first step today:
              </Typography>
              
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 3 }}>
                <input
                  type="text"
                  placeholder="Full Name"
                  value={name}
                  onChange={handleNameChange}
                  style={{
                    width: '95%',
                    padding: '12px',
                    borderRadius: '8px',
                    border: '1px solid #E0E0E0'
                  }}
                />
                <input
                  type="email"
                  placeholder="Email Address"
                  value={email}
                  onChange={handleEmailChange}
                  style={{
                    width: '95%',
                    padding: '12px',
                    borderRadius: '8px',
                    border: '1px solid #E0E0E0'
                  }}
                />
                {errorMessage && (
                  <Typography color="error" variant="body2">
                    {errorMessage}
                  </Typography>
                )}
              </Box>
              
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <GradientButton 
                  variant="contained" 
                  onClick={handleStartAssessment}
                  endIcon={<ArrowRight />}
                  fullWidth
                >
                  Get Your Free Assessment
                </GradientButton>
                
                <OutlinedButton 
                  variant="outlined" 
                  onClick={handleScheduleCall}
                  endIcon={<ArrowRight />}
                  fullWidth
                >
                  Book Your Free Clarity Call
                </OutlinedButton>
                
                <Typography variant="body2" color="text.secondary">
                  No credit card required • 100% free • No obligation
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box id="section-guarantee" sx={{ py: 6, bgcolor: 'rgba(33, 150, 243, 0.03)', borderRadius: '16px', my: 6, p: 4 }}>
            <Grid container spacing={4} alignItems="center" disableEqualOverflow>
              <Grid item xs={12} md={6} disableEqualOverflow>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Guarantee />
                </Box>
              </Grid>
              
              <Grid item xs={12} md={6} disableEqualOverflow>
                <Typography variant="h5" component="h2" fontWeight="bold" sx={{ mb: 2 }}>
                  60-Day Transformation Guarantee
                </Typography>
                
                <Typography variant="body1" sx={{ mb: 3 }}>
                  I'm so confident in the Willful Positivity program that I offer a complete satisfaction guarantee. If you fully participate in the program and don't experience meaningful transformation within 60 days, I'll refund your investment in full.
                </Typography>
                
                <Typography variant="body1" sx={{ mb: 3 }}>
                  This isn't just about feeling good—it's about creating tangible results in your life. My guarantee ensures you can commit with complete confidence.
                </Typography>
                
                <GradientButton 
                  variant="contained" 
                  onClick={handleScheduleCall}
                  endIcon={<ArrowRight />}
                >
                  Schedule Your Free Call Now
                </GradientButton>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>

      <ConfirmationDialog 
        open={showConfirmationMessage} 
        onClose={() => setShowConfirmationMessage(false)}
        email={email}
      />
    </>
  );
};

export default LandingPage;