import React, { useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, IconButton, LinearProgress } from '@mui/material';
import { Play, Pause, Volume2, VolumeX, Maximize } from 'lucide-react';

const VideoContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '500px',
  borderRadius: '16px',
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: '#000',
  boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
}));

const VideoElement = styled('video')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const ControlsOverlay = styled(Box)(({ theme, isPlaying }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  padding: theme.spacing(2),
  background: 'linear-gradient(transparent, rgba(0,0,0,0.7))',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  opacity: isPlaying ? 0 : 1,
  transition: 'opacity 0.3s ease',
  '&:hover': {
    opacity: 1,
  },
}));

const FallbackContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(3),
  textAlign: 'center',
}));

const ProgressBar = styled(LinearProgress)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  height: 4,
  backgroundColor: 'rgba(255,255,255,0.2)',
  '& .MuiLinearProgress-bar': {
    backgroundColor: theme.palette.primary.main,
  }
}));

const VideoPlayer = ({ 
  src, 
  poster = '', 
  title = 'Video Introduction',
  fallbackText = "Video content is loading or unavailable. Please check your connection."
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);
  const videoRef = useRef(null);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleMuteToggle = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const handleFullScreen = () => {
    if (videoRef.current) {
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen();
      } else if (videoRef.current.webkitRequestFullscreen) {
        videoRef.current.webkitRequestFullscreen();
      } else if (videoRef.current.msRequestFullscreen) {
        videoRef.current.msRequestFullscreen();
      }
    }
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      const currentProgress = (videoRef.current.currentTime / videoRef.current.duration) * 100;
      setProgress(currentProgress);
    }
  };

  const handleLoadedData = () => {
    setIsLoaded(true);
    setError(false);
  };

  const handleError = () => {
    setError(true);
    setIsLoaded(false);
  };

  return (
    <VideoContainer>
      <VideoElement
        ref={videoRef}
        src={src}
        poster={poster}
        onTimeUpdate={handleTimeUpdate}
        onLoadedData={handleLoadedData}
        onError={handleError}
        onClick={handlePlayPause}
      />
      
      {!isLoaded && !error && (
        <FallbackContent>
          <Typography variant="h6" color="text.primary" sx={{ mb: 2 }}>
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Loading video...
          </Typography>
        </FallbackContent>
      )}

      {error && (
        <FallbackContent>
          <Typography variant="h6" color="text.primary" sx={{ mb: 2 }}>
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {fallbackText}
          </Typography>
        </FallbackContent>
      )}

      <ControlsOverlay isPlaying={isPlaying && !error}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <IconButton 
            onClick={handlePlayPause} 
            sx={{ color: 'white' }}
            size="small"
          >
            {isPlaying ? <Pause size={20} /> : <Play size={20} />}
          </IconButton>
          
          <IconButton 
            onClick={handleMuteToggle} 
            sx={{ color: 'white' }}
            size="small"
          >
            {isMuted ? <VolumeX size={20} /> : <Volume2 size={20} />}
          </IconButton>
        </Box>
        
        <IconButton 
          onClick={handleFullScreen} 
          sx={{ color: 'white' }}
          size="small"
        >
          <Maximize size={20} />
        </IconButton>
      </ControlsOverlay>
      
      <ProgressBar variant="determinate" value={progress} />
    </VideoContainer>
  );
};

export default VideoPlayer;