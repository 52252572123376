// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TakeAssessment from './pages/TakeAssessment';
import Assessment from './pages/Assessment';
import ScheduleCall from './pages/ScheduleCall';
import ScrollToTop from './components/ScrollToTop';
import SharedAssessment from './pages/SharedAssessment';
import LandingPage from './pages/LandingPage';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/TakeAssessment" element={<TakeAssessment />} />
        <Route path="/Assessment" element={<Assessment />} />
        <Route path="/ScheduleCall" element={<ScheduleCall />} />
        <Route path="/shared-assessment/:token" element={<SharedAssessment />} />
      </Routes>
    </Router>
  );
}

export default App;