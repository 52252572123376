// NavigationBanner.js
import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const NavContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  height: '80px',
  backgroundColor: 'rgba(255, 255, 255, 0.95)',
  backdropFilter: 'blur(5px)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 20px',
  boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
  zIndex: 1000,
}));

const Logo = styled(Link)(({ theme }) => ({
  width: '200px',
  height: '70px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  transition: 'opacity 0.2s ease',
  '&:hover': {
    opacity: 0.8,
  },
  '& img': {
    height: '100%',
    width: 'auto',
    objectFit: 'contain',
  }
}));

const NavigationBanner = () => {
  return (
    <NavContainer>
      <Logo to="/">
        <img src="/logo.png" alt="Willful Positivity Logo" />
      </Logo>
    </NavContainer>
  );
};

export default NavigationBanner;