import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { alpha } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { Brain, Heart, Home, Briefcase, Wallet, Activity, Users, HeartHandshake, Lightbulb } from 'lucide-react';
import Alert from '@mui/material/Alert';
import NavigationBanner from '../components/NavigationBanner';

// Custom styled components
const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: '16px',
  boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: '0 8px 30px rgba(0,0,0,0.12)',
  }
}));

const CategoryCard = styled(Card)(({ theme, level }) => {
  const colors = {
    High: theme.palette.success.light,
    Moderate: theme.palette.warning.light,
    Low: theme.palette.error.light
  };
  
  return {
    borderRadius: '12px',
    borderLeft: `4px solid ${colors[level] || theme.palette.primary.main}`,
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'translateY(-4px)'
    }
  };
});

// Categories data (same as Assessment.js)
const categories = [
  {
    id: 'personal-growth',
    title: 'Personal Growth',
    description: 'Mindset, learning, and self-improvement habits.',
    icon: Brain,
    color: '#7C4DFF'
  },
  {
    id: 'fun-leisure',
    title: 'Fun and Leisure',
    description: 'How well you recharge and enjoy life.',
    icon: Heart,
    color: '#FF6D00'
  },
  {
    id: 'home-environment',
    title: 'Home Environment',
    description: 'Your space and its impact on your well-being.',
    icon: Home,
    color: '#00BFA5'
  },
  {
    id: 'career',
    title: 'Career',
    description: 'How aligned and fulfilling your work life is.',
    icon: Briefcase,
    color: '#2979FF'
  },
  {
    id: 'money',
    title: 'Money',
    description: 'Your financial health and stability.',
    icon: Wallet,
    color: '#00C853'
  },
  {
    id: 'health',
    title: 'Health',
    description: 'Your physical, mental, and emotional well-being.',
    icon: Activity,
    color: '#D50000'
  },
  {
    id: 'friends-family',
    title: 'Friends and Family',
    description: 'The quality of your relationships and support network.',
    icon: Users,
    color: '#FF4081'
  },
  {
    id: 'significant-other',
    title: 'Significant Other',
    description: 'Love, companionship, and shared goals.',
    icon: HeartHandshake,
    color: '#AA00FF'
  }
];

const getScoreInterpretation = (score) => {
  if (score >= 80) {
    return 'Showing remarkable progress in their journey!';
  }
  else if (score >= 60 && score < 80) {
    return 'Built solid foundations and making great strides';
  }
  else if (score >= 40 && score < 60) {
    return 'Actively working on positive changes in life';
  }
  else if (score >= 20 && score < 40) {
    return 'Taking important first steps toward positive change';
  }
  else {
    return 'At the start of their transformation journey';
  }
};

const getCategoryScoreLevel = (score) => {
  if (score >= 40) return 'High';
  if (score >= 20) return 'Moderate';
  return 'Low';
};

const LoadingSpinner = () => (
  <div className="flex justify-center items-center min-h-screen">
    <StyledCard sx={{ p: 4, maxWidth: '400px', width: '100%' }}>
      <CardContent>
        <Typography variant="h6" gutterBottom textAlign="center">
          Loading Assessment...
        </Typography>
        <LinearProgress />
      </CardContent>
    </StyledCard>
  </div>
);

const ErrorMessage = ({ message }) => (
  <div className="flex justify-center items-center min-h-screen">
    <StyledCard sx={{ p: 4, maxWidth: '400px', width: '100%' }}>
      <CardContent>
        <Alert severity="error">
          {message}
        </Alert>
      </CardContent>
    </StyledCard>
  </div>
);

const GaugeChart = ({ score }) => {
  // Determine color and label based on score
  const getColorAndLabel = (value) => {
    if (value >= 80) {
      return { 
        color: '#4CAF50', // Strong green
        label: 'Exceptional'
      };
    } else if (value >= 60 && value < 80) {
      return { 
        color: '#8BC34A', // Light green
        label: 'Strong'
      };
    } else if (value >= 40 && value < 60) {
      return { 
        color: '#FFC107', // Amber
        label: 'Growing'
      };
    } else if (value >= 20 && value < 40) {
      return { 
        color: '#FF9800', // Orange
        label: 'Developing'
      };
    }
    return { 
      color: '#F44336', // Red
      label: 'Beginning'
    };
  };

  const { color, label} = getColorAndLabel(score);
  
  // Calculate angles for the gauge
  const startAngle = -180;
  const endAngle = 0;
  const range = endAngle - startAngle;
  const valueAngle = startAngle + (range * (score / 100));
  
  // Calculate points for the needle
  const centerX = 275;
  const centerY = 150;
  const needleLength = 100;
  const needleAngleRad = (valueAngle * Math.PI) / 180;
  const needleX = centerX + needleLength * Math.cos(needleAngleRad);
  const needleY = centerY + needleLength * Math.sin(needleAngleRad);

  // Generate tick marks
  const generateTicks = () => {
    const ticks = [];
    for (let i = 0; i <= 10; i++) {
      const angle = startAngle + (range * (i / 10));
      const angleRad = (angle * Math.PI) / 180;
      const outerRadius = 120;
      const innerRadius = 110;
      
      const outerX = centerX + outerRadius * Math.cos(angleRad);
      const outerY = centerY + outerRadius * Math.sin(angleRad);
      const innerX = centerX + innerRadius * Math.cos(angleRad);
      const innerY = centerY + innerRadius * Math.sin(angleRad);
      
      ticks.push(
        <line
          key={i}
          x1={innerX}
          y1={innerY}
          x2={outerX}
          y2={outerY}
          stroke="#999"
          strokeWidth="1"
        />
      );

      // Add labels for some ticks
      if (i % 2 === 0) {
        const labelRadius = 130;
        const labelX = centerX + labelRadius * Math.cos(angleRad);
        const labelY = centerY + labelRadius * Math.sin(angleRad);
        ticks.push(
          <text
            key={`label-${i}`}
            x={labelX}
            y={labelY}
            textAnchor="middle"
            dominantBaseline="middle"
            fontSize="10"
            fill="#666"
          >
            {i * 10}
          </text>
        );
      }
    }
    return ticks;
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center">
      <div className="flex flex-col items-center">
        <div className="w-96 h-96">
          <svg viewBox="0 0 550 190" className="w-full h-full">
            {/* Background arc */}
            <path
              d={`M ${centerX} ${centerY} L ${centerX - 120} ${centerY} A 120 120 0 0 1 ${centerX + 120} ${centerY} Z`}
              fill="none"
              stroke="#e0e0e0"
              strokeWidth="15"
              strokeLinecap="round"
            />
            
            {/* Ticks */}
            {generateTicks()}
            
            {/* Needle */}
            <path
              d={`M ${centerX} ${centerY} L ${needleX} ${needleY}`}
              stroke={color}
              strokeWidth="4"
              strokeLinecap="round"
            />
            
            {/* Center circle */}
            <circle
              cx={centerX}
              cy={centerY}
              r="8"
              fill={color}
            />
            
            {/* Score display */}
            <text
              x={centerX}
              y={centerY + 35}
              textAnchor="middle"
              fontSize="24"
              fontWeight="bold"
              fill={color}
            >
              {score} 
            </text>
          </svg>
        </div>
        
        {/* Labels */}
        <div className="text-center mt-4">
          <div className="text-2xl font-bold" style={{ color, textAlign:'center' }}>
            {label}
          </div>
        </div>
      </div>
    </div>
  );
};

const SharedAssessment = () => {
  const { token } = useParams();
  const [assessment, setAssessment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAssessment = async () => {
      try {
        const response = await axios.get(
          `https://willfulpositivity.com/view-shared-assessment.php?token=${token}`
        );
        
        if (response.data.success) {
          setAssessment(response.data);
        }
      } catch (error) {
        setError('Assessment not found or link has expired');
      } finally {
        setLoading(false);
      }
    };

    fetchAssessment();
  }, [token]);

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage message={error} />;
  if (!assessment) return <ErrorMessage message="Assessment not found" />;

  const calculateCategoryTotal = (categoryId) => {
    const categoryScores = assessment.request.categoryScores.find(
      cat => categories.find(c => c.title === cat.title)?.id === categoryId
    );
    return categoryScores ? categoryScores.score : 0;
  };

  return (
    <>
      <NavigationBanner />
      <div className="flex justify-center items-center min-h-screen bg-gray-50 py-8 px-4" style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#F9FAFB',
        padding: '2rem 1rem',
        marginTop: '70px'
      }}>
        <StyledCard sx={{ maxWidth: '44rem', width: '100%', p: { xs: 2, md: 4 } }}>
          <CardContent>
            <div className="space-y-8">
              <div className="text-center">
                <Typography variant="h5" className="mb-4 font-bold" style={{ textAlign: 'center' }}>
                  {assessment.name}'s Willful Positivity Score
                </Typography>
                
                <GaugeChart score={parseFloat(assessment.request.overallScore)} />
                
                <Typography variant="h6" className="text-gray-600 mb-6" style={{marginBottom:'10px',marginTop:'10px',textAlign:'center'}}>
                  {getScoreInterpretation(parseFloat(assessment.request.overallScore))}
                </Typography>
              </div>

              {/* Overall Summary Section */}
              <StyledCard className="p-6" style={{marginBottom:'20px',padding:'20px'}}>
                <div className="flex items-start gap-4">
                  <div className="bg-blue-50 rounded-full p-3 mt-1">
                    <Lightbulb size={24} className="text-blue-500" />
                  </div>
                  <div className="flex-1">
                    <Typography variant="h6" className="mb-4 font-semibold">
                      Overall Analysis for {assessment.name}
                    </Typography>
                    <div className="prose max-w-none text-gray-700">
                      <Typography variant="body1">
                        {assessment.response.summary}
                      </Typography>
                    </div>
                  </div>
                </div>
              </StyledCard>

              {/* Category Results */}
              <div className="grid gap-4">
                {categories.map((category) => {
                  const categoryScore = calculateCategoryTotal(category.id);
                  const scoreLevel = getCategoryScoreLevel(categoryScore);
                  const Icon = category.icon;
                  const insights = assessment.response.recommendations[category.id.replace(/-/g, '_')];

                  return (
                    <CategoryCard key={category.id} level={scoreLevel} className="p-4" style={{marginBottom:'10px',padding:'10px'}}>
                      <div className="flex items-center gap-3 mb-2">
                        <div 
                          className="w-10 h-10 rounded-full flex items-center justify-center" 
                          style={{ backgroundColor: alpha(category.color, 0.1) }}
                        >
                          <Icon size={20} style={{ color: category.color }} />
                        </div>
                        <div className="flex-1">
                          <div className="flex justify-between items-center">
                            <Typography variant="subtitle1" className="font-semibold">
                              {category.title}
                            </Typography>
                            <Typography variant="subtitle1" className="font-bold">
                              {categoryScore}/50
                            </Typography>
                          </div>
                          <div className="w-full bg-gray-200 h-2 rounded-full mt-1">
                            <div
                              className="h-2 rounded-full transition-all duration-500"
                              style={{ 
                                width: `${(categoryScore / 50) * 100}%`,
                                backgroundColor: category.color 
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      
                      <div className="ml-12 mt-2">
                        <Typography variant="body2" className="text-gray-600 mb-2">
                          {scoreLevel === 'High'
                            ? 'Strong performance in this area!'
                            : scoreLevel === 'Moderate'
                            ? 'Room for growth'
                            : 'Focus area for improvement'}
                        </Typography>
                        
                        {insights && (
                          <div className="mt-3 p-3 bg-gray-50 rounded-lg border border-gray-100">
                            <Typography variant="body2" className="text-gray-700 mb-2">
                              {insights.comment}
                            </Typography>
                            {insights.suggestions && insights.suggestions.length > 0 && (
                              <div className="mt-2">
                                <Typography variant="body2" className="text-gray-700 font-medium mb-1">
                                  Suggestions:
                                </Typography>
                                <ul className="list-disc pl-4 space-y-1">
                                  {insights.suggestions.map((suggestion, index) => (
                                    <li key={index}>
                                      <Typography variant="body2" className="text-gray-700">
                                        {suggestion}
                                      </Typography>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </CategoryCard>
                  );
                })}
              </div>

              {/* Footer Section */}
              <div className="text-center mt-8 border-t pt-6" style={{textAlign:'center'}}>
                <Typography variant="body2" color="text.secondary">
                  Assessment completed on {new Date(assessment.timestamp).toLocaleDateString()}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                  Interested in discovering your Willful Positivity score?{' '}
                  <a 
                    href="/" 
                    style={{ 
                      color: '#2196F3', 
                      textDecoration: 'underline',
                      fontWeight: 500 
                    }}
                  >
                    Take the free assessment
                  </a>
                </Typography>
              </div>

            </div>
          </CardContent>
        </StyledCard>
      </div>
    </>
  );
};

export default SharedAssessment;