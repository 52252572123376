import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAnalytics from '../hooks/useAnalytics';
import { Target, Heart, Brain, ArrowRight, Star, CheckCircle, Clock } from 'lucide-react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import NavigationBanner from '../components/NavigationBanner';
import ConfirmationDialog from '../components/ConfirmationDialog';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

// Custom styled components
const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: '16px',
  boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
  transition: 'all 0.3s ease',
  overflow: 'visible',
  '&:hover': {
    boxShadow: '0 8px 30px rgba(0,0,0,0.12)',
  }
}));

const IconBox = styled('div')(({ theme }) => ({
  width: '48px',
  height: '48px',
  borderRadius: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: alpha(theme.palette.primary.main, 0.12),
  color: theme.palette.primary.main,
  flexShrink: 0,
}));

const StyledButton = styled('button')(({ theme }) => ({
  width: '100%',
  padding: '16px',
  borderRadius: '8px',
  background: 'linear-gradient(45deg, #2196F3, #673AB7)',
  color: 'white',
  border: 'none',
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  cursor: 'pointer',
  transition: 'opacity 0.3s ease',
  '&:hover': {
    opacity: 0.9
  }
}));

const OutlinedButton = styled(StyledButton)(({ theme }) => ({
  background: 'transparent',
  border: '2px solid #2196F3',
  color: '#2196F3',
  '&:hover': {
    background: alpha('#2196F3', 0.1),
    opacity: 1
  }
}));

const TakeAssessment = () => {
  const navigate = useNavigate();
  const { trackEvent } = useAnalytics();
  const [name, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  //Get URL Parameters
  const [searchParams] = useState(new URLSearchParams(window.location.search));
  const isVerified = searchParams.get('verified') === 'true';
  const verifiedEmail = searchParams.get('email');
  const verifiedName = searchParams.get('name');
  const subscriberType = searchParams.get('type');

  useEffect(() => {
    if (isVerified && verifiedEmail) {
      setEmail(verifiedEmail || '');
      setFullName(verifiedName || '');
      setShowSuccessMessage(true);
      
      // Store verification data in localStorage
      localStorage.subscriber_email = verifiedEmail;
      localStorage.subscriber_name = verifiedName || '';
      localStorage.email_confirmed = 'true';
      localStorage.subscriber_type = subscriberType;

      // Identify verified user with Brevo
      const firstName = verifiedName ? verifiedName.split(" ")[0] : '';
      const lastName = verifiedName ? verifiedName.split(" ").slice(1).join(" ") : '';

      window.Brevo.push(function () {
        window.Brevo.identify({
          identifiers: {
            email_id: verifiedEmail   /*required*/
          },
          attributes: {            /*optional*/
            FIRSTNAME: firstName,
            LASTNAME: lastName,
          },
        });
      });
      
      // Auto-proceed to assessment after delay (optional)
      const timer = setTimeout(() => {
        navigate('/Assessment');
      }, 2000); // 2 second delay
      
      return () => clearTimeout(timer);
    }
  }, [isVerified, verifiedEmail, verifiedName, subscriberType, navigate]);

  // Umami tracking function
  const trackUmamiEvent = (eventName, eventData = {}) => {
    if (typeof window !== 'undefined' && window.umami) {
      window.umami.track(eventName, eventData);
    }
  };

  // Track scroll depth
  useEffect(() => {
    let ticking = false;
    const scrollThresholds = [25, 50, 75, 90];
    const reachedThresholds = new Set();

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          const scrollPercent = (window.scrollY + window.innerHeight) / document.documentElement.scrollHeight * 100;
          
          scrollThresholds.forEach(threshold => {
            if (scrollPercent >= threshold && !reachedThresholds.has(threshold)) {
              trackUmamiEvent('scroll_depth', { depth: `${threshold}%` });
              reachedThresholds.add(threshold);
            }
          });

          ticking = false;
        });

        ticking = true;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Track testimonial visibility
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            trackUmamiEvent('testimonial_viewed', {
              id: entry.target.dataset.testimonialId
            });
          }
        });
      },
      { threshold: 0.5 }
    );

    const testimonialElements = document.querySelectorAll('[data-testimonial-id]');
    testimonialElements.forEach(el => observer.observe(el));

    return () => observer.disconnect();
  }, []);

  const handleNameChange = (e) => {
    setFullName(e.target.value);
    if (e.target.value.trim() && !name.trim()) {
      trackUmamiEvent('name_field_entered');
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (e.target.value.trim() && !email.trim()) {
      trackUmamiEvent('email_field_entered');
    }
  };

  const handleScheduleCall = () => {
    trackUmamiEvent('schedule_call_clicked', {
      source: 'benefits_section'
    });
    trackEvent('schedule_call_click', {
      location: 'benefits_card'
    });
    navigate('/ScheduleCall');
  };

  const handleSubscribeAndProceed = async () => {
    setErrorMessage('');
    
    if (!name.trim() || !email.trim()) {
        setErrorMessage('Please enter your full name and email address.');
        return;
    }

    const firstName = name.split(" ")[0];
    const lastName = name.split(" ").slice(1).join(" ");

    // Identify user with Brevo
    window.Brevo.push(function () {
        window.Brevo.identify({
            identifiers: {
                email_id: email   /*required*/
            },
            attributes: {            /*optional*/
                FIRSTNAME: firstName,
                LASTNAME: lastName,
            },
        });
    });

    try {
          const response = await fetch('https://willfulpositivity.com/subscribe.php', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({ name, email }),
          });

          if (response.ok) {
            const data = await response.json();
            localStorage.subscriber_type = data.data;
            localStorage.subscriber_email = data.email;
            localStorage.subscriber_name = name;
            localStorage.email_confirmed = data.emailConfirmed;
              
              if (!data.emailConfirmed) {
                  setShowConfirmationMessage(true);
              } else {
                  navigate('/Assessment');
              }
          } else {
              setErrorMessage('Subscription failed or you are already subscribed.');
          }
      } catch (error) {
          console.error(error);
          setErrorMessage('An error occurred while subscribing. Please try again.');
      }
  };

  const testimonials = [
    {
      name: "Sarah Johnson",
      role: "Marketing Executive",
      content: "The Willful Positivity assessment helped me identify key areas for growth. After the coaching sessions, I've seen remarkable improvements in both my career and personal life.",
      rating: 5
    },
    {
      name: "Michael Chen",
      role: "Startup Founder",
      content: "This assessment and the following coaching sessions provided exactly the structure and guidance I needed to achieve better work-life balance.",
      rating: 5
    }
  ];

  const benefits = [
    {
      icon: <Target size={24} />,
      title: "Clarity & Direction",
      description: "Gain crystal-clear insights into your life's current state and future direction"
    },
    {
      icon: <Brain size={24} />,
      title: "Expert Guidance",
      description: "Access personalized coaching based on proven psychological principles"
    },
    {
      icon: <Heart size={24} />,
      title: "Lasting Transformation",
      description: "Create sustainable positive changes across all areas of your life"
    }
  ];

  return (
    <>
    <NavigationBanner />
    <div style={{ 
      minHeight: '100vh',
      background: 'linear-gradient(180deg, #F0F7FF 0%, #FFFFFF 100%)',
      padding: '118px 16px 48px 16px' // Added top padding to account for nav bar
    }}>
      <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
        {/* Hero Section */}
        <div style={{ textAlign: 'center', marginBottom: '64px' }}>
          <Typography
            variant="h2"
            component="h1"
            sx={{
              fontWeight: 'bold',
              background: 'linear-gradient(45deg, #2196F3, #673AB7)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              mb: 3
            }}
          >
            Transform Your Life <br/>with Purpose & Clarity
          </Typography>
          
          <Typography variant="h5" color="text.secondary" sx={{ mb: 4, maxWidth: '800px', mx: 'auto' }}>
            Start with our free Willful Positivity Assessment and discover your path to a more productive and fulfilling life
          </Typography>

          {/* Social Proof */}
          <div style={{ display: 'flex', justifyContent: 'center', gap: '32px', marginBottom: '32px' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Star size={20} color="#FFB400" />
              <Typography variant="body1" fontWeight="600">4.9/5</Typography>
              <Typography variant="body2" color="text.secondary">(200+ reviews)</Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <CheckCircle size={20} color="#4CAF50" />
              <Typography variant="body2" color="text.secondary">1000+ Lives Transformed</Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Clock size={20} color="#2196F3" />
              <Typography variant="body2" color="text.secondary">10-15 min assessment</Typography>
            </div>
          </div>
        </div>

        {/* Main Content Grid */}
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gap: '32px', marginBottom: '64px' }}>
          {/* Left Column - Benefits */}
          <StyledCard>
            <CardContent sx={{ p: 4 }}>
              <Typography variant="h5" fontWeight="600" sx={{ mb: 3 }}>
                What You'll Discover
              </Typography>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                {benefits.map((benefit, index) => (
                  <div key={index} style={{ display: 'flex', gap: '16px' }}>
                    <IconBox>{benefit.icon}</IconBox>
                    <div>
                      <Typography variant="h6" fontWeight="600">
                        {benefit.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {benefit.description}
                      </Typography>
                    </div>
                  </div>
                ))}
              </div>
              <div style={{marginBottom:'10px',padding:'10px'}}></div>
              <OutlinedButton onClick={handleScheduleCall}>
                Schedule a Call with Coach Alma
                <ArrowRight size={20} />
              </OutlinedButton>
              <Typography variant="body2" color="text.secondary" align="center" style={{marginTop:'10px'}}>
                No Obligation • 30-min consultation
              </Typography>
            </CardContent>
          </StyledCard>

          {/* Right Column - Sign Up Form */}
          <StyledCard sx={{ background: '#FFFFFF' }}>
            <CardContent sx={{ p: 4 }}>
              <Typography variant="h5" fontWeight="600" sx={{ mb: 2 }}>
                Begin Your Journey
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
                Take the free assessment now and receive:
              </Typography>

              <div style={{ marginBottom: '24px' }}>
                {['Comprehensive Life Assessment Report', 'Personalized Growth Opportunities', 'Free 30-min Coaching consultation'].map((item, index) => (
                  <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '8px', marginBottom: '12px' }}>
                    <CheckCircle size={20} color="#4CAF50" />
                    <Typography variant="body1">{item}</Typography>
                  </div>
                ))}
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', gap: '16px'}}>
                <input
                  type="text"
                  placeholder="Full Name"
                  value={name}
                  onChange={handleNameChange}
                  style={{
                    width: '95%',
                    padding: '12px',
                    borderRadius: '8px',
                    border: '1px solid #E0E0E0'
                  }}
                />
                <input
                  type="email"
                  placeholder="Email Address"
                  value={email}
                  onChange={handleEmailChange}
                  style={{
                    width: '95%',
                    padding: '12px',
                    borderRadius: '8px',
                    border: '1px solid #E0E0E0'
                  }}
                />
                {errorMessage && (
                  <Typography color="error" variant="body2">
                    {errorMessage}
                  </Typography>
                )}
                <StyledButton onClick={handleSubscribeAndProceed}>
                  Start Free Assessment
                  <ArrowRight size={20} />
                </StyledButton>
                <Typography variant="body2" color="text.secondary" align="center">
                  No credit card required • 100% free
                </Typography>
              </div>
            </CardContent>
          </StyledCard>
        </div>

        <ConfirmationDialog 
            open={showConfirmationMessage} 
            onClose={() => setShowConfirmationMessage(false)}
            email={email}
        />

        {/* Testimonials Section */}
        <div style={{ marginBottom: '64px' }}>
          <Typography variant="h5" fontWeight="600" align="center" sx={{ mb: 4 }}>
            What Others Are Saying
          </Typography>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gap: '32px' }}>
            
              {testimonials.map((testimonial, index) => (
              <StyledCard 
                key={index}
                data-testimonial-id={`testimonial-${index}`}
              >
                <CardContent sx={{ p: 4 }}>
                  <div style={{ display: 'flex', gap: '4px', marginBottom: '16px' }}>
                    {[...Array(testimonial.rating)].map((_, i) => (
                      <Star key={i} size={20} color="#FFB400" fill="#FFB400" />
                    ))}
                  </div>
                  <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
                    "{testimonial.content}"
                  </Typography>
                  <Typography variant="body1" fontWeight="600">
                    {testimonial.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {testimonial.role}
                  </Typography>
                </CardContent>
              </StyledCard>
            ))}
          </div>
        </div>

        {/* Final CTA */}
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h5" fontWeight="600" sx={{ mb: 2 }}>
            Ready to Transform Your Life?
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
            Join thousands who have already taken the first step towards a more purposeful life
          </Typography>
          <StyledButton
            onClick={handleSubscribeAndProceed}
            style={{ maxWidth: '300px', margin: '0 auto' }}
          >
            Take the Free Assessment
            <ArrowRight size={20} />
          </StyledButton>
        </div>
      </div>
    </div>

    <Snackbar
      open={showSuccessMessage}
      autoHideDuration={6000}
      onClose={() => setShowSuccessMessage(false)}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert 
        onClose={() => setShowSuccessMessage(false)} 
        severity="success"
        sx={{ width: '100%' }}
      >
        Your email has been successfully verified! You're being redirected to the assessment...
      </Alert>
    </Snackbar>
    </>
  );
};

export default TakeAssessment;