import { useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

const useAnalytics = () => {
  const location = useLocation();

  // Track page views
  useEffect(() => {
    const trackPage = () => {
      if (window.umami) {
        window.umami.track('pageview', { url: location.pathname });
      }
    };
  
    if (window.umami) {
      trackPage();
    } else {
      window.addEventListener('umami.loaded', trackPage);
    }
  
    return () => {
      window.removeEventListener('umami.loaded', trackPage);
    };
  }, [location]);

  // Custom event tracking
  const trackEvent = useCallback((eventName, eventData = {}) => {
    if (window.umami) {
      window.umami.track(eventName, eventData);
    }
  }, []);

  // Assessment specific events
  const trackAssessmentProgress = useCallback((step, totalSteps) => {
    trackEvent('assessment_progress', {
      step,
      totalSteps,
      percentComplete: Math.round((step / totalSteps) * 100)
    });
  }, [trackEvent]);

  const trackAssessmentComplete = useCallback((score) => {
    trackEvent('assessment_complete', {
      overallScore: score
    });
  }, [trackEvent]);

  const trackCategoryScore = useCallback((category, score) => {
    trackEvent('category_score', {
      category,
      score
    });
  }, [trackEvent]);

  // Scheduling events
  const trackSchedulingClick = useCallback(() => {
    trackEvent('scheduling_initiated');
  }, [trackEvent]);

  // PDF generation events
  const trackPDFGeneration = useCallback(() => {
    trackEvent('pdf_generated');
  }, [trackEvent]);

  return {
    trackEvent,
    trackAssessmentProgress,
    trackAssessmentComplete,
    trackCategoryScore,
    trackSchedulingClick,
    trackPDFGeneration
  };
};

export default useAnalytics;