import React, { useState, useEffect } from 'react';
import useAnalytics from '../hooks/useAnalytics';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Calendar, Clock, ExternalLink } from 'lucide-react';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import NavigationBanner from '../components/NavigationBanner';

// Custom styled components
const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: '16px',
  boxShadow: '0 10px 40px rgba(0,0,0,0.1)',
  overflow: 'visible',
  transition: 'all 0.3s ease',
  position: 'relative',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 15px 50px rgba(0,0,0,0.15)',
  }
}));

const CoachAvatar = styled(Avatar)(({ theme }) => ({
  width: 200,
  height: 200,
  border: `4px solid white`,
  boxShadow: '0 8px 20px rgba(0,0,0,0.1)'
}));

const CallBenefit = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
    transform: 'translateY(-2px)',
  }
}));

const ScheduleIframe = styled('iframe')(({ theme }) => ({
  width: '100%',
  height: '600px',
  border: 'none',
  borderRadius: '12px',
  boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
}));

const ScheduleCall = () => {
  const bookingUrl = 'https://meet.brevo.com/willfulpositivity';
  const { trackEvent } = useAnalytics();
  const [iframeLoading, setIframeLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Check if screen width is below 900px (typical tablet breakpoint)
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 900);
    };
    
    // Run on initial load
    checkMobile();
    
    // Add event listener
    window.addEventListener('resize', checkMobile);
    
    // Check if user is already identified and identify them with Brevo if they have a stored email
    const storedEmail = localStorage.getItem('subscriber_email');
    const emailConfirmed = localStorage.getItem('email_confirmed') === 'true';
    
    if (storedEmail && emailConfirmed) {
      const storedName = localStorage.getItem('subscriber_name') || 'User';
      const firstName = storedName.split(" ")[0];
      const lastName = storedName.split(" ").slice(1).join(" ");
      
      window.Brevo.push(function () {
        window.Brevo.identify({
          identifiers: {
            email_id: storedEmail   /*required*/
          },
          attributes: {            /*optional*/
            FIRSTNAME: firstName,
            LASTNAME: lastName,
          },
        });
      });
    }
    
    // Cleanup
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const handleIframeLoad = () => {
    setIframeLoading(false);
    trackEvent('booking_iframe_loaded');
  };

  const benefits = [
    {
      title: "In-depth Discussion of Results",
      description: "Gain deeper insights into your assessment scores and what they mean for your personal growth journey.",
      icon: <Calendar size={20} className="text-indigo-600" />
    },
    {
      title: "Personalized Strategy Session",
      description: "Develop a custom action plan based on your unique strengths and growth opportunities.",
      icon: <Clock size={20} className="text-pink-600" />
    },
    {
      title: "Expert Guidance",
      description: "Learn evidence-based techniques to enhance your willful positivity in everyday situations.",
      icon: <ExternalLink size={20} className="text-teal-600" />
    }
  ];

  return (
    <>
      <NavigationBanner />
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        background: 'linear-gradient(to bottom, #F0F7FF, #FFFFFF)',
        padding: '2rem 1rem',
        marginTop: '40px'
      }}>
        <Fade in={true} timeout={800}>
          <StyledCard sx={{ 
            maxWidth: '1200px', 
            width: '100%', 
            mt: 7, 
            p: { xs: 2, md: 4 } 
          }}>
            {/* For mobile: stack vertically */}
            {isMobile ? (
              <>
                <CardContent sx={{ pt: 7 }}>
                  {/* Coach Info */}
                  <div className="text-center mb-6">
                  <CoachAvatar src="/coach.jpg" alt="Coach Alma" />
                    <Typography variant="h4" className="font-bold mb-2">
                      Meet Coach Alma
                    </Typography>
                    
                    <Typography variant="body1" className="text-gray-500 mb-2">
                      Life Coach & Willful Positivity Expert
                    </Typography>
                    
                    <div className="flex justify-center gap-2 mb-4" style={{marginTop:'10px',marginBottom:'10px'}}>
                      <Chip label="Willful Positivity" color="primary" size="small" style={{marginRight:'10px'}}/>
                      <Chip label="Life Balance" variant="outlined" size="small" style={{marginRight:'10px'}}/>
                      <Chip label="Personal Growth" variant="outlined" size="small" />
                    </div>
                  </div>

                  <Typography variant="body1" className="text-gray-700 text-lg mb-8">
                    Ready to transform your Willful Positivity insights into actionable change? 
                    Schedule a one-on-one discovery call with Alma to explore personalized strategies 
                    that align with your assessment results.
                  </Typography>

                  <div className="space-y-4 mb-8" style={{marginTop:'10px'}}>
                    {benefits.map((benefit, index) => (
                      <CallBenefit key={index} style={{marginBottom:'10px'}}>
                        <div className="p-2 rounded-full bg-blue-50">
                          {benefit.icon}
                        </div>
                        <div>
                          <Typography variant="subtitle1" className="font-medium mb-1">
                            {benefit.title}
                          </Typography>
                          <Typography variant="body2" className="text-gray-600">
                            {benefit.description}
                          </Typography>
                        </div>
                      </CallBenefit>
                    ))}
                  </div>

                  <Divider className="my-6" />

                  <div className="space-y-6" style={{marginTop:'10px', marginBottom:'20px',textAlign:'center'}}>
                    <div className="text-center space-y-2">
                      <Typography variant="h6" className="font-medium" style={{textAlign:'center'}}>
                        Your First Session is Complimentary
                      </Typography>
                      <Typography variant="body2" className="text-gray-500" style={{textAlign:'center'}}>
                        30-minute video consultation • No obligation • Personalized feedback
                      </Typography>
                    </div>
                  </div>

                  {/* Iframe Section */}
                  <Box sx={{ position: 'relative', mt: 4 }}>
                    {iframeLoading && (
                      <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 1
                      }}>
                        <CircularProgress color="primary" />
                        <Typography variant="body2" sx={{ ml: 2 }}>
                          Loading scheduler...
                        </Typography>
                      </Box>
                    )}
                    
                    <ScheduleIframe 
                      src={bookingUrl} 
                      title="Schedule a call with Coach Alma" 
                      onLoad={handleIframeLoad}
                    />
                  </Box>

                  <Typography
                    variant="body2"
                    className="text-gray-400 text-center mt-4" 
                    style={{marginTop:'10px',textAlign:'center'}}
                  >
                    By booking, you agree to our scheduling and cancellation policies.
                  </Typography>
                </CardContent>
              </>
            ) : (
              /* For desktop: side by side layout */
              <Grid container spacing={4}>
                {/* Left side: Coach info */}
                <Grid item xs={12} md={5}>
                  <CardContent sx={{ pt: 7 }}>
                    <div className="text-center mb-6">
                      <CoachAvatar src="/coach.jpg" alt="Coach Alma" />
                      <Typography variant="h4" className="font-bold mb-2">
                        Meet Coach Alma
                      </Typography>
                      
                      <Typography variant="body1" className="text-gray-500 mb-2">
                        Life Coach & Willful Positivity Expert
                      </Typography>
                      
                      <div className="flex justify-center gap-2 mb-4" style={{marginTop:'10px',marginBottom:'10px'}}>
                        <Chip label="Willful Positivity" color="primary" size="small" style={{marginRight:'10px'}}/>
                        <Chip label="Life Balance" variant="outlined" size="small" style={{marginRight:'10px'}}/>
                        <Chip label="Personal Growth" variant="outlined" size="small" />
                      </div>
                    </div>

                    <Typography variant="body1" className="text-gray-700 text-lg mb-8">
                      Ready to transform your Willful Positivity insights into actionable change? 
                      Schedule a one-on-one discovery call with Alma to explore personalized strategies 
                      that align with your assessment results.
                    </Typography>

                    <div className="space-y-4 mb-8" style={{marginTop:'10px'}}>
                      {benefits.map((benefit, index) => (
                        <CallBenefit key={index} style={{marginBottom:'10px'}}>
                          <div className="p-2 rounded-full bg-blue-50">
                            {benefit.icon}
                          </div>
                          <div>
                            <Typography variant="subtitle1" className="font-medium mb-1">
                              {benefit.title}
                            </Typography>
                            <Typography variant="body2" className="text-gray-600">
                              {benefit.description}
                            </Typography>
                          </div>
                        </CallBenefit>
                      ))}
                    </div>

                    <Divider className="my-6" style={{marginTop:'40px'}}/>

                    <div className="space-y-6" style={{marginTop:'10px', marginBottom:'20px',textAlign:'center'}}>
                    <div className="text-center space-y-2">
                      <Typography variant="h6" className="font-medium" style={{textAlign:'center'}}>
                        Your First Session is Complimentary
                      </Typography>
                      <Typography variant="body2" className="text-gray-500" style={{textAlign:'center'}}>
                        30-minute video consultation • No obligation • Personalized feedback
                      </Typography>
                    </div>
                  </div>
                  </CardContent>
                </Grid>
                
                {/* Right side: Booking iframe */}
                <Grid item xs={12} md={7}>
                  <CardContent>
                    <Box sx={{ 
                      position: 'relative', 
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column'
                    }}>
                      
                      {iframeLoading && (
                        <Box sx={{ 
                          display: 'flex', 
                          justifyContent: 'center', 
                          alignItems: 'center',
                          position: 'absolute',
                          top: '50%',
                          left: 0,
                          right: 0,
                          zIndex: 1
                        }}>
                          <CircularProgress color="primary" />
                          <Typography variant="body2" sx={{ ml: 2 }}>
                            Loading scheduler...
                          </Typography>
                        </Box>
                      )}
                      
                      <Box sx={{ flexGrow: 1 }}>
                        <ScheduleIframe 
                          src={bookingUrl} 
                          title="Schedule a call with Coach Alma" 
                          onLoad={handleIframeLoad}
                          style={{ height: '950px' }}
                        />
                      </Box>
                      
                      <Typography
                        variant="body2"
                        className="text-gray-400 text-center mt-4" 
                        style={{marginTop:'10px',textAlign:'center'}}
                      >
                        By booking, you agree to our scheduling and cancellation policies.
                      </Typography>
                    </Box>
                  </CardContent>
                </Grid>
              </Grid>
            )}
          </StyledCard>
        </Fade>
      </div>
    </>
  );
};

export default ScheduleCall;